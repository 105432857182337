import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import HighlightIcon from '@mui/icons-material/Highlight'
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { yellow } from '@mui/material/colors'
import { useEffect, useState } from 'react'
import lfStore from '../../../lfstore/lfStore'
import { dataLayersAreas, dataLayersPoints } from '../../../utils/FakeDataBase/data_fake'

const defaultFeatureName = 'Area sin nombre'

const AreaRow = ({
  feature,
  handleFeature,
  type,
  saved,
  handleName,
  handleExclusionAreaTypeChange = null,
  handleAddDependencies = null,
  handleRemoveDependencies = null,
  handleOtherName = null,
  handleHasGrazingManagement = null,
  handleSelectFeature = null,
  handleRemoveFeature = null,
  error = false,
}) => {
  // Data state
  const [featureName, setFeatureName] = useState(defaultFeatureName)
  const [otherName, setOtherName] = useState('')
  const [fgChoosed, setFgChoosed] = useState('')
  const [exclusionAreaTypes, setExclusionAreaTypes] = useState([])
  const [selectedType, setSelectedType] = useState('')

  // Control states
  const [nameChanged, setNameChanged] = useState(false)
  const [otherNameChanged, setOtherNameChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataLayerOptions, setDataLayerOptions] = useState([])
  const [isMonitoringSite, setIsMonitoringSite] = useState(false)

  // Useful const
  const pointLayers = ['unassignedPoints', 'monitoringSites', 'otherSites']

  const handleChangeFeatureGroup = event => {
    setFgChoosed(event.target.value)
    handleFeature(feature, type, event.target.value)
    setLoading(false)
  }

  const handleTemporalFeatureNameChange = event => {
    setNameChanged(true)
    setFeatureName(event.target.value)
  }

  const handleFeatureNameChange = () => {
    if (nameChanged) {
      handleName(feature, type, featureName)
      setNameChanged(false)
    }
  }

  const handleTemporalOtherNameChange = event => {
    setOtherNameChanged(true)
    setOtherName(event.target.value)
  }

  const handleOtherNameChange = () => {
    if (otherNameChanged) {
      handleOtherName(feature, otherName)
      setOtherNameChanged(false)
    }
  }

  const handleTypeChange = event => {
    handleRemoveDependencies(feature.properties.name)
    let id = null
    exclusionAreaTypes.forEach(exclusionAreaType => {
      if (exclusionAreaType.es_AR === event.target.value) {
        id = exclusionAreaType.id
      }
    })
    id = id !== null ? id : 99
    handleExclusionAreaTypeChange(feature, id)
    setSelectedType(event.target.value)
  }

  const handleIsMonitoringSiteChange = () => {
    if (!isMonitoringSite) {
      handleFeature(feature, type, 'monitoringSites')
    } else {
      handleFeature(feature, type, 'unassignedPoints')
    }

    setLoading(false)
  }

  useEffect(() => {
    if (type) {
      setFgChoosed(type)
      if (type === 'monitoringSites') {
        setIsMonitoringSite(true)
      }
    }
    const name = feature?.properties?.otherName || feature?.properties?.name || null
    if (name) {
      setFeatureName(name)
    } else {
      handleName(feature, type, defaultFeatureName)
    }
  }, [feature, type, error, handleName])

  useEffect(() => {
    async function getExclusionAreaTypes() {
      const exclusionAreaTypesCached = await lfStore.getItem('exclusionAreaTypes')
      setExclusionAreaTypes(exclusionAreaTypesCached)

      const id = feature?.properties?.exclusionAreaTypeId

      if (id !== null) {
        handleRemoveDependencies(feature.properties.name)
        handleExclusionAreaTypeChange(feature, id)
        exclusionAreaTypesCached.forEach(exclusionAreaType => {
          if (exclusionAreaType?.id === id) {
            setSelectedType(exclusionAreaType.id)
          }
        })
      } else {
        handleAddDependencies(feature.properties.name)
      }
    }

    if (type === 'exclusionAreas') {
      getExclusionAreaTypes()
    }
  }, [])

  useEffect(() => {
    if (feature?.properties?.otherName) {
      setOtherName(feature?.properties?.otherName)
    } else {
      setOtherName(feature?.properties?.name)
    }
  }, [feature])

  useEffect(() => {
    if (feature?.geometry?.type === 'Polygon' || feature?.geometry?.type === 'MultiPolygon') {
      setDataLayerOptions(dataLayersAreas)
    } else {
      setDataLayerOptions(dataLayersPoints)
    }
  }, [feature])

  return (
    feature &&
    feature.properties &&
    feature.geometry && (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: 2,
            marginBottom: 1,
          }}
        >
          <Grid container alignItems="center" spacing={1}>
            {pointLayers.includes(fgChoosed) ? (
              <Grid item sx={{ textAlign: 'center', pb: 1 }} xs={12}>
                <Typography color={error ? 'error' : ''} variant="bodyBold">
                  {pointLayers.includes(fgChoosed) ? 'Sitio - ' : ''}
                  {feature.properties.otherName
                    ? feature.properties.otherName
                    : feature.properties.name}
                </Typography>
              </Grid>
            ) : null}
            {fgChoosed === 'exclusionAreas' ? (
              <>
                <Grid item lg={3} md={6} xl={4} xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="exclusion-category-label" size="small">
                      Tipo de área de exclusión
                    </InputLabel>
                    <Select
                      defaultValue={
                        feature?.properties?.exclusionAreaTypeId
                          ? feature?.properties?.exclusionAreaTypeId
                          : ''
                      }
                      disabled={saved}
                      error={selectedType === ''}
                      id="exclusion-category-select"
                      label="Tipo de área de exclusión"
                      size="small"
                      value={selectedType}
                      onChange={handleTypeChange}
                    >
                      {exclusionAreaTypes?.map(areaType => {
                        return (
                          <MenuItem key={areaType.id} size="small" value={areaType.id}>
                            {areaType?.es_AR}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={3} md={6} xl={4} xs={6}>
                  <FormControl fullWidth component="fieldset">
                    <Box alignItems="center" display="flex" flexDirection="column">
                      <Checkbox
                        checked={feature.properties.hasGrazingManagement === true}
                        disabled={saved}
                        name="hasGrazingManagement"
                        onChange={e => {
                          handleHasGrazingManagement(feature, e.target.checked)
                        }}
                      />
                      <FormLabel component="legend" style={{ textAlign: 'center', width: '100%' }}>
                        Con pastoreo
                      </FormLabel>
                    </Box>
                  </FormControl>
                </Grid>
              </>
            ) : null}
            {!pointLayers.includes(type) ? (
              <Grid item lg={5} md={6} xl={5} xs={6}>
                {feature?.geometry?.type === 'Point' ? (
                  <Typography
                    size="small"
                    sx={{
                      margin: 2,
                    }}
                  >
                    {feature?.properties?.name ?? 'Punto sin nombre'}
                  </Typography>
                ) : null}
                {feature?.geometry?.type === 'Polygon' ||
                feature?.geometry?.type === 'MultiPolygon' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <TextField
                      disabled={saved}
                      error={error}
                      label="Nombre"
                      size="small"
                      value={featureName}
                      variant="outlined"
                      onBlur={handleFeatureNameChange}
                      onChange={handleTemporalFeatureNameChange}
                    />
                    {loading ? (
                      <LinearProgress
                        sx={{
                          width: '100%',
                          height: 5,
                        }}
                      />
                    ) : null}
                  </Box>
                ) : null}
              </Grid>
            ) : null}
            <Grid item lg={3} md={6} xl={5} xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" size="small">
                  Categoría
                </InputLabel>
                <Select
                  disabled={saved || isMonitoringSite}
                  label="Categoría"
                  labelId="demo-simple-select-label"
                  placeholder="Seleccionar"
                  size="small"
                  value={fgChoosed}
                  onChange={handleChangeFeatureGroup}
                >
                  {dataLayerOptions.map(layer => {
                    return (
                      <MenuItem
                        key={layer.farmLayerType}
                        disabled={layer.farmLayerType === 'monitoringSites'}
                        size="small"
                        value={layer.farmLayerType}
                      >
                        {layer.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            {feature?.geometry?.type === 'Point' ? (
              <Grid item lg={5} md={6} xl={5} xs={6}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Typography
                    size="small"
                    sx={{
                      mx: { xs: 0, xl: 2 },
                    }}
                  >
                    Sitio de monitoreo
                  </Typography>
                  <Checkbox
                    checked={isMonitoringSite}
                    disabled={saved}
                    onChange={handleIsMonitoringSiteChange}
                  />
                </Box>
              </Grid>
            ) : null}
            {selectedType === 'Otro' ? (
              <Grid item lg={5} md={6} xl={5} xs={6}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    disabled={saved}
                    label="Otro nombre"
                    size="small"
                    value={otherName}
                    variant="outlined"
                    onBlur={handleOtherNameChange}
                    onChange={handleTemporalOtherNameChange}
                  />
                </Box>
              </Grid>
            ) : null}
            {feature?.properties?.area ? (
              <Grid item lg={3} md={12} sx={{ textAlign: 'center' }} xl={2} xs={12}>
                <Typography variant="body">
                  {typeof feature?.properties?.area === 'number'
                    ? `${feature?.properties?.area.toFixed(2)} ha`
                    : feature?.properties?.area}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            marginLeft: 1,
          }}
        >
          <IconButton onClick={() => handleSelectFeature(feature)}>
            <HighlightIcon
              sx={{ color: feature.properties.selected ? yellow[700] : 'primary.main' }}
            />
          </IconButton>
          {handleRemoveFeature && (
            <DeleteOutlineIcon
              sx={{
                cursor: 'pointer',
                color: 'red',
              }}
              onClick={() => handleRemoveFeature(feature, type)}
            />
          )}
        </Box>
      </Box>
    )
  )
}

export default AreaRow
