import { useAuth0 } from '@auth0/auth0-react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useCallback, useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext'
import endpoints from '../../services/ruutsApi/endpoints'
import { ruutsApi } from '../../services/ruutsApi/index'
import { a11yProps } from '../../utils/tabsHandle'
import { userRoles } from '../../utils/userRoles'
import ExclusionAreaHome from '../Clasification/ExclusionArea/ExclusionAreaHome'
import StratificationHome from '../Clasification/Stratification/StratificationHome'
import EventPlanning from '../EventPlanning/EventPlanning'
import TabPanel from '../NavBar/TabPanel'
import SamplePlanningHome from '../SamplePlanning/SamplePlanningHome'
import { useViewOnly } from '../../hooks/useViewOnly'

const MonitoringHome = () => {
  const [value, setValue] = useState(0)
  const [samplingAreas, setSamplingAreas] = useState()
  const [monitoringSitesSaved, setMonitoringSitesSaved] = useState(false)
  const [samplingAreasSaved, setSamplingAreasSaved] = useState(false)
  const [exclusionAreas, setExclusionAreas] = useState()
  const [monitoringSites, setMonitoringSites] = useState([])
  const [mapWidth] = useState((window.innerWidth / 3) * 2)
  const [mapHeight] = useState(window.innerHeight)
  const {
    partialChanges,
    setPartialChanges,
    setConfirmationModalConfig,
    innerTab,
    hideNavbar,
    currentFarm,
    userRoleSelected,
  } = useContext(AppContext)
  const { getAccessTokenSilently } = useAuth0()
  const [canEditClasification, setCanEditClasification] = useState(false)
  const { isViewOnly } = useViewOnly()

  const handleCanEditClasification = useCallback(
    mS => {
      if (isViewOnly) return false

      // If there is at least a random monitoring site, all classifications are disabled
      // Admin user role can edit all classifications
      return (
        !mS.some(monitorSite => {
          return monitorSite.isRandomSite
        }) || userRoleSelected === userRoles.Admin
      )
    },
    [userRoleSelected, isViewOnly],
  )

  useEffect(() => {
    if (!currentFarm || !getAccessTokenSilently) return

    async function getFarm() {
      const token = await getAccessTokenSilently()

      const sA = await ruutsApi.samplingAreas.getUncroppedByFarm({ farmId: currentFarm.id, token })
      setSamplingAreas(sA)
      setSamplingAreasSaved(sA.length > 0)

      const eA = await ruutsApi.getApiData(
        endpoints.exclusionAreas,
        { farmId: currentFarm.id },
        token,
      )
      setExclusionAreas(eA)

      const sites = await ruutsApi.getApiData(endpoints.sites, { farmId: currentFarm.id }, token)
      setCanEditClasification(handleCanEditClasification(sites))
      setMonitoringSites(sites)
    }
    getFarm()
  }, [currentFarm, getAccessTokenSilently, handleCanEditClasification])

  useEffect(() => {
    setCanEditClasification(handleCanEditClasification(monitoringSites))
  }, [handleCanEditClasification, monitoringSites])

  useEffect(() => {
    if (!monitoringSitesSaved) return

    const reloadMonitoringSites = async () => {
      const token = await getAccessTokenSilently()
      const mS = await ruutsApi.getApiData(endpoints.sites, { farmId: currentFarm.id }, token)
      setMonitoringSites(mS)
      setMonitoringSitesSaved(false)
    }
    reloadMonitoringSites()
  }, [monitoringSitesSaved, currentFarm, getAccessTokenSilently])

  const handleTabChange = (event, newValue) => {
    if (partialChanges) {
      setConfirmationModalConfig({
        open: true,
        title: 'Cambios sin guardar',
        message: 'Si cambia de pantalla, se perderán los cambios. ¿Desea continuar?',
        confirmLabel: 'Continuar',
        cancelLabel: 'Cancelar',
        confirmAction: () => {
          localStorage.setItem('monitoring-home-last-index', newValue)
          setPartialChanges(false)
          setValue(newValue)
        },
      })
    } else {
      localStorage.setItem('monitoring-home-last-index', newValue)
      setValue(newValue)
    }
  }

  useEffect(() => {
    const lastIndex = localStorage.getItem('monitoring-home-last-index')
    if (!lastIndex) {
      localStorage.setItem('monitoring-home-last-index', 0)
      setValue(0)
    }

    // Tab interna en caso de redirección
    if (innerTab) {
      setValue(innerTab)
    }
  }, [innerTab])

  return (
    <>
      {currentFarm && (
        <Box sx={{ width: '100%' }}>
          <Box
            display={hideNavbar ? 'none' : 'inherit'}
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tabs
              aria-label="basic tabs example"
              gap={2}
              value={value || 0}
              onChange={handleTabChange}
            >
              <Tab label="Área de exclusión" sx={{ textTransform: 'none' }} {...a11yProps(0)} />
              <Tab label="Estratificación" sx={{ textTransform: 'none' }} {...a11yProps(1)} />
              <Tab
                label="Plan"
                sx={{ textTransform: 'none' }}
                {...a11yProps(2)}
                disabled={!samplingAreasSaved}
              />
              <Tab label="Eventos" sx={{ textTransform: 'none' }} {...a11yProps(3)} />
            </Tabs>
          </Box>
          {/* Tabs Panels */}
          <TabPanel index={0} value={value}>
            <ExclusionAreaHome
              canEditClasification={canEditClasification}
              exclusionAreas={exclusionAreas}
              mapHeight={mapHeight}
              mapWidth={mapWidth}
              samplingAreas={samplingAreas}
              setExclusionAreas={setExclusionAreas}
              setTabValue={handleTabChange}
            />
          </TabPanel>
          <TabPanel index={1} value={value}>
            <StratificationHome
              canEditClasification={canEditClasification}
              mapHeight={mapHeight}
              mapWidth={mapWidth}
              samplingAreas={samplingAreas}
              setSamplingAreas={setSamplingAreas}
              setSamplingAreasSaved={setSamplingAreasSaved}
              setTabValue={handleTabChange}
            />
          </TabPanel>
          {samplingAreasSaved && (
            <TabPanel index={2} value={value}>
              <SamplePlanningHome setMonitoringSitesSaved={setMonitoringSitesSaved} />
            </TabPanel>
          )}
          <TabPanel index={3} value={value}>
            <EventPlanning />
          </TabPanel>
        </Box>
      )}
    </>
  )
}

export default MonitoringHome
