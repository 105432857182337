/* eslint-disable no-shadow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unstable-nested-components */
import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import EditTwoTone from '@mui/icons-material/EditTwoTone'
import SearchIcon from '@mui/icons-material/Search'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Button, Chip, Divider, Link, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import lfStore from '../../lfstore/lfStore'
import refDataById from '../../services/localRefData/refDataById'
import { formModes } from '../../utils/constants'
import { customSnackbarError } from '../../utils/Snackbar/Snackbar'
import { BooleanRowData } from './BooleanRowData'
import { FindingsRowData } from './FindingsRowData'
import { MetricEventStatusRowData } from './MetricEventStatusRowData'

const dataFieldTypes = [
  'TextField',
  'DateField',
  'Select',
  'RadioGroup',
  'CheckBox',
  'CheckBoxGroup',
  'FieldArray',
  'FileInput',
]
const baseOptions = {
  filterType: 'checkbox',
  selectableRows: 'single',
  selectableRowsHeader: false,
  enableNestedDataAccess: '.',
  download: false,
  print: false,
  pagination: false,
  textLabels: {
    filter: {
      all: 'Todos',
    },
  },
}

const disabledOptions = {
  selectableRows: 'none',
  sort: false,
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}

const booleanFilterOptions = {
  names: ['Sí', 'No'],
  logic: (value, filterVal) => {
    const show =
      (filterVal.indexOf('Sí') >= 0 && value === true) ||
      (filterVal.indexOf('No') >= 0 && value !== true)
    return !show
  },
}

const DataTable = ({
  data,
  handleMetricReset,
  formDefinition,
  handleSelect,
  handleDelete,
  paddocks,
  formMode,
}) => {
  const [rowData, setRowData] = useState()
  const [columns, setColumns] = useState()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const options = {
    ...baseOptions,
    ...(formMode === formModes.Disable ? disabledOptions : {}),
  }

  const fieldSelectedButtons = (selected, formMode) => {
    switch (formMode) {
      case formModes.View:
        return (
          <Button
            startIcon={<VisibilityIcon sx={{ color: 'blue' }} />}
            onClick={() => handleSelect(selected)}
          >
            Ver
          </Button>
        )
      case formModes.Review:
        return (
          <Button
            startIcon={<SearchIcon sx={{ color: 'blue' }} />}
            onClick={() => handleSelect(selected)}
          >
            Revisar
          </Button>
        )
      case formModes.Edit:
        return (
          <>
            <Button
              startIcon={<EditTwoTone sx={{ color: 'blue' }} />}
              onClick={() => handleSelect(selected)}
            >
              Editar
            </Button>
            <Button
              startIcon={<DeleteTwoTone sx={{ color: 'red' }} />}
              onClick={() => {
                handleDelete(selected)
              }}
            >
              Borrar
            </Button>
          </>
        )
      default: // No buttons
        return null
    }
  }

  const tableActions = selected => (
    <Stack
      key="data-table-dc-actions"
      alignItems="center"
      direction="row"
      display="flex"
      flexGrow={1}
      justifyContent="space-between"
      minHeight="64px"
      pl="24px"
      pr="24px"
    >
      <Typography variant="h6">{formDefinition.label}</Typography>
      <Stack
        alignItems="center"
        direction="row"
        display="flex"
        flexGrow={1}
        justifyContent="flex-end"
      >
        {fieldSelectedButtons(selected, formMode)}
      </Stack>
    </Stack>
  )

  useEffect(() => {
    async function setUpData() {
      try {
        /* Get referenceData Keys from lfstore */
        const refDataKeys = await lfStore.keys()

        /* Build columns array from formDefinition, for given field Types set in constant dataFieldTypes */
        const columns = formDefinition.fields.reduce(
          (columns, field) => {
            if (field.label && field.name && dataFieldTypes.includes(field.component_type)) {
              const column = {
                name: field.name,
                label: field.label,
                options: {
                  customBodyRender: value => {
                    if (typeof value === 'boolean') {
                      return value ? <CheckIcon color="success" /> : null
                    }
                    if (Array.isArray(value)) {
                      return value.join(' - ')
                    }
                    return value
                  },
                },
              }
              if (field.component_type === 'FieldArray') {
                column.options.filter = false
              } else {
                column.options.filter = true
                column.options.filterType = 'dropdown'
              }

              columns.push(column)
            }

            return columns
          },
          [
            {
              name: 'id',
              label: 'id',
              options: { display: false, filter: false },
            },
            {
              name: 'index',
              label: '#',
              options: {
                filter: false,
                sort: false,
                customBodyRenderLite: dataIndex => dataIndex,
              },
            },
            {
              name: 'isDraft',
              label: 'Borrador',
              options: {
                customBodyRender: value => {
                  if (value) {
                    return <Chip color="warning" label="Borrador" />
                  }
                  return value
                },
                filterOptions: booleanFilterOptions,
              },
            },
            {
              name: 'metricYear',
              label: 'Año',
            },
            {
              name: 'paddocks',
              label: 'Lote/s',
            },
            {
              name: 'isVerified',
              label: 'Verificada',
              options: {
                filterOptions: booleanFilterOptions,
              },
            },
            {
              name: 'findings',
              label: 'Observaciones',
              options: {
                filter: false,
              },
            },
            {
              name: 'notApplicable',
              label: 'No aplica',
              options: {
                customBodyRender: (value, tableMeta) => {
                  if (!value) tableMeta.columnData.display = false
                  return value ? <CheckIcon color="success" /> : null
                },
                filter: false,
              },
            },
            {
              name: 'metricStatusId',
              label: 'Estado',
              options: { display: true, filter: false },
            },
          ],
        )
        setColumns(columns)
        const columnNames = columns.map(col => col.name)
        /* Build dataset for each row and resolve refDataById if the field has a refDataKey in the form Definition */
        const rows = await Promise.all(
          data.map(async record => {
            const recordData = record.data || record
            const recordId = record.id || recordData.id
            const row = {
              id: recordId,
              paddocks: recordData.selectedPaddocks
                ? recordData.selectedPaddocks
                    .map(id => {
                      const [feature] = paddocks.features.filter(f => f.properties.id === id)
                      return feature.properties.name
                    })
                    .join(' - ')
                : 'General',
              findings: (
                <FindingsRowData
                  findings={record.Findings || []}
                  handleMetricReset={handleMetricReset}
                  readOnly={formMode !== formModes.Review} // Los finding solo se pueden resolver en modo revisión
                />
              ),
              isVerified: <BooleanRowData value={record.isVerified} />,
              metricStatusId: (
                <MetricEventStatusRowData
                  handleMetricReset={handleMetricReset}
                  metricId={record.id}
                  statusId={record.metricStatusId}
                />
              ),
            }
            /* Función recursiva? */
            await Promise.all(
              Object.keys(recordData).map(async key => {
                if (columnNames.includes(key)) {
                  const [fieldDefinition] = formDefinition.fields.filter(f => f.name === key)
                  if (fieldDefinition && fieldDefinition.component_type === 'FieldArray') {
                    const nestedData = []
                    for (const nestedRecord of recordData[key]) {
                      const nestedRow = await Promise.all(
                        Object.keys(nestedRecord).map(async recordKey => {
                          const [nestedField] = fieldDefinition.fields.filter(
                            f => f.name === recordKey,
                          )
                          if (!nestedField) {
                            throw new Error(
                              `Inconsistencia en la métrica (${recordKey.id}). No se encontró el valor ${recordKey} en ninguno de los campos definidos: ${JSON.stringify(fieldDefinition.fields.map(field => field.name))}`,
                            )
                          }
                          if (nestedField.refDataKey) {
                            const value = await refDataById(
                              nestedField.refDataKey,
                              parseInt(nestedRecord[recordKey], 10),
                            )
                            return (
                              <Stack
                                key={`${recordId}-${key}-${recordKey}-stack`}
                                direction="column"
                                display="flex"
                                flexGrow={1}
                              >
                                <Typography>
                                  <b>{nestedField.label}:</b> {value?.es_AR || ''}
                                </Typography>
                              </Stack>
                            )
                          }
                          return (
                            <Stack
                              key={`${recordId}-${key}-${recordKey}-stack`}
                              direction="column"
                              display="flex"
                              flexGrow={1}
                              width="200px"
                            >
                              <Typography>
                                <b>{nestedField.label}: </b>
                                {nestedRecord[recordKey]}
                              </Typography>
                            </Stack>
                          )
                        }),
                      )
                      nestedData.push(nestedRow)
                      nestedData.push(
                        <Divider key={`${recordId}-${key}-${nestedData.length}-div`} />,
                      )
                    }
                    row[key] = (
                      <Stack
                        key={`dtr-${key}-${row.id}`}
                        alignItems="center"
                        direction="column"
                        display="flex"
                      >
                        {nestedData.map(nestedRow => nestedRow)}
                      </Stack>
                    )
                  } else if (fieldDefinition && fieldDefinition.component_type === 'FileInput') {
                    const nestedData = []
                    for (const nestedRecord of recordData[key]) {
                      const fileName = nestedRecord.substring(nestedRecord.lastIndexOf('/') + 1)
                      const nestedRow = (
                        <Link
                          key={`${fileName} _link_${nestedData.length}`}
                          href={nestedRecord}
                          target="_blank"
                        >
                          {fileName}
                        </Link>
                      )
                      nestedData.push(
                        nestedRow,
                        <Divider key={`${fileName}_div_${nestedData.length}`} />,
                      )
                    }
                    row[key] = <Stack>{nestedData.map(nestedRow => nestedRow)}</Stack>
                  } else if (fieldDefinition && fieldDefinition.component_type === 'DateField') {
                    if (!fieldDefinition.required && !recordData[key]) {
                      row[key] = ''
                      return
                    }
                    const formattedDate = recordData[key]?.split('-').reverse().join('/')
                    row[key] = formattedDate
                  } else if (
                    fieldDefinition &&
                    Object.keys(fieldDefinition).includes('refDataKey') &&
                    refDataKeys.includes(fieldDefinition.refDataKey)
                  ) {
                    if (Array.isArray(recordData[key])) {
                      const values = await Promise.all(
                        recordData[key].map(async id => {
                          const value = await refDataById(
                            fieldDefinition.refDataKey,
                            parseInt(id, 10),
                          )
                          return value?.es_AR || ''
                        }),
                      )
                      row[key] = values
                    } else {
                      const value = await refDataById(
                        fieldDefinition.refDataKey,
                        parseInt(recordData[key], 10),
                      )
                      row[key] = value?.es_AR || ''
                    }
                  } else {
                    row[key] = recordData[key]
                  }
                }
              }),
            )
            return row
          }),
        )
        setRowData(rows)
      } catch (error) {
        customSnackbarError(error.message, error, enqueueSnackbar, closeSnackbar)
      }
    }
    setUpData()
  }, [closeSnackbar, data, enqueueSnackbar, formDefinition, formMode, handleMetricReset, paddocks])

  return (
    data &&
    columns &&
    rowData && (
      <MUIDataTable
        columns={columns}
        components={{
          TableToolbarSelect: tableActions,
        }}
        data={rowData}
        options={options}
        title={formDefinition?.label || ''}
      />
    )
  )
}

export default DataTable
