/* eslint-disable max-len */
export const messagesKeys = {
  FILL_FORM_SUPPORT_CONTACT_BUTTON: {
    es_AR: 'Completar formulario',
    en_US: 'Fill form',
  },
  ERROR_FORM_BUTTON: {
    es_AR: 'Aceptar',
    en_US: 'Accept',
  },
  FORM_LOADING_FARM_MESSAGE: {
    es_AR: 'Cargando el establecimiento',
    es_US: 'Loading farm',
  },
  ERROR_IMPORTING_FARM_FROM_KML_FILE_TITLE: {
    es_AR: 'Error en carga de kml',
    en_US: 'Error loading kml',
  },
  ERROR_IMPORTING_FARM_FROM_KML_FILE_DETAIL: {
    es_AR: 'Por favor adjunte el kml en el siguiente formulario para recibir soporte.',
    en_US: 'Please attach the kml in the following form to receive support.',
  },
  ERROR_IMPORTING_FARM_FROM_KML_FILE_FORM_MESSAGE: {
    es_AR:
      'No se puede cargar el KML seleccionado: "%1". Adjuntar el archivo que se intentó cargar.',
    en_US: 'Cannot load selected KML: "%1". Attach the file that was attempted to load.',
  },
  ERROR_CONTACT_SUPPORT_DETAIL: {
    es_AR: 'Por favor póngase en contacto con nuestro equipo para obtener asistencia.',
    en_US: 'Please contact our team for assistance.',
  },
  ERROR_FARM_WITH_MULTIPLE_DEALS_AND_PROGRAMS_TITLE: {
    es_AR: 'Error al cargar establecimiento',
    en_US: 'Error loading farm',
  },
  ERROR_FARM_WITH_MULTIPLE_DEALS_AND_PROGRAMS_DETAIL: {
    es_AR: 'Por favor complete el siguiente formulario para recibir soporte.',
    en_US: 'Please complete the following form to receive support.',
  },
  ERROR_FARM_WITH_MULTIPLE_DEALS_AND_PROGRAMS_MESSAGE: {
    es_AR: 'El establecimiento se encuentra registrado en dos programas distintos.',
    en_US: 'The farm is registered in two programs. Contact the operations area to resolve it.',
  },
  ERROR_IMPORTING_FARM_FROM_KML_UNSUPPORTED_MULTI_GEOMETRY_INCLUDE_MULTI_GEOMETRY_MESSAGE: {
    es_AR:
      'La capa "%2" (categoría "%1") es una multigeometría que contiene otra multigeometría. RPD solo admite polígonos individuales o agrupamientos con una sola combinación de geometrías.',
    en_US:
      'Error loading kml: The layer "%2" (category "%1") is a multiGeometry that contains another multiGeometry. RPD only supports individual polygons or groupings with a single combination of geometries.',
  },
  ERROR_IMPORTING_FARM_FROM_KML_UNSUPPORTED_MULTI_GEOMETRY_DUPLICATES_NAMES_GEOMETRY_MESSAGE: {
    es_AR:
      'Se encontró más de una capa con el nombre "%2" (categoría "%1"). Elimine la capa duplicada y pruebe nuevamente.',
    en_US:
      'More than one layer with the name "%1" (category "%2") was found. Remove the duplicate layer and try again.',
  },
  ERROR_IMPORTING_FARM_FROM_KML_UNSUPPORTED_MULTI_GEOMETRY_WITHOUT_POLYGON_MESSAGE: {
    es_AR:
      'La capa "%2" (categoría "%1") no contiene polígono/s. Elimine la capa y pruebe nuevamente.',
    en_US:
      'Error loading kml: The layer "%2" (category "%1") does not contain polygon/s. Remove the layer and try again.',
  },
  ERROR_OVERLAP_LAYER_AREA_MESSAGE: {
    es_AR:
      'Las capas "%1" y "%2" se superponen mas de lo permitido. Corrija la superposición y vuelva a cargar el archivo.',
    en_US:
      'The layers "%1" and "%2" overlap more than allowed. Correct the overlap and reload the file.',
  },
  ERROR_TOTAL_OVERLAP_LAYER_AREA_MESSAGE: {
    es_AR:
      'Las capas "%1" y "%2" se superponen por completo. Corrija la superposición y vuelva a cargar el archivo.',
    es_US: 'The layers "%1" and "%2" overlap completely. Correct the overlap and reload the file.',
  },
  ERROR_CANNOT_EDIT_GEOMETRY_APPLY_CHANGES_MESSAGE: {
    es_AR: 'Es necesario aplicar los cambios en el polígono en proceso antes de continuar.',
    en_US: 'You must apply the changes to the current polygon before continuing.',
  },
  ERROR_CANNOT_EDIT_SAMPLING_AREA_EMPTY_GEOMETRIES_MESSAGE: {
    es_AR: 'Es necesario al menos un estrato para guardar la estratificación.',
    en_US: 'At least one stratum is required to save the stratification.',
  },
  ERROR_CANNOT_EDIT_SAMPLING_AREA_EMPTY_GEOMETRY_NAME_MESSAGE: {
    es_AR: 'Todos los estratos deben tener un nombre.',
    en_US: 'All strata must have a name.',
  },
  ERROR_CANNOT_EDIT_SAMPLING_AREA_GEOMETRY_LOWER_THAN_MINIMUM_ALLOWED_MESSAGE: {
    es_AR:
      'El estrato "%1" debe ser más grande que %2 hectáreas para ser guardado. Elimínelo o agrande su tamaño',
    en_US:
      'The stratum "%1" must be larger than %2 hectares to be saved. Delete it or enlarge its size',
  },
  ERROR_CANNOT_EDIT_SAMPLING_AREA_DUPLICATE_NAMES_NAME_MESSAGE: {
    es_AR: 'No puede haber nombres de estratos duplicados.',
    en_US: 'There cannot be duplicate stratus names.',
  },
  ERROR_CANNOT_EDIT_GEOMETRY_EMPTY_EXCLUSION_AREA_NAME_MESSAGE: {
    es_AR: 'Todos las áreas de exclusión deben tener un nombre.',
    en_US: 'All exclusion areas must have a name.',
  },
  ERROR_CANNOT_EDIT_FARM_SUBDIVISION_DUPLICATE_NAMES_NAME_MESSAGE: {
    es_AR: 'No puede haber nombres de lotes duplicados.',
    en_US: 'There cannot be duplicate allotments names.',
  },
  ERROR_CANNOT_EDIT_FARM_SUBDIVISION_EMPTY_GEOMETRY_NAME_MESSAGE: {
    es_AR: 'Todos los lotes deben tener un nombre.',
    en_US: 'All allotment must have a name.',
  },
  ERROR_CANNOT_EDIT_FARM_SUBDIVISION_EMPTY_GEOMETRIES_MESSAGE: {
    es_AR: 'Es necesario al menos un lote para guardar el loteo.',
    en_US: 'At least one allotment is required to save the allotments.',
  },
  ERROR_CANNOT_EDIT_FARM_SUBDIVISION_YEAR_NON_SELECTED_MESSAGE: {
    es_AR: 'Es necesario seleccionar un año para continuar.',
    en_US: 'A year must be selected to continue.',
  },
  ERROR_CANNOT_EDIT_EXCLUSION_AREAS_EMPTY_GEOMETRIES_MESSAGE: {
    es_AR: 'Es necesario al menos un área de exclusión para guardar el loteo.',
    en_US: 'At least one exclusion area is required to save the allotments.',
  },
  ERROR_CANNOT_EDIT_EXCLUSION_AREA_EMPTY_GEOMETRY_NAME_MESSAGE: {
    es_AR: 'Todos las áreas de exclusión deben tener un nombre.',
    en_US: 'All exclusion areas must have a name.',
  },
  ERROR_CANNOT_EDIT_EXCLUSION_AREAS_OTHER_CATEGORY_NAME_EMPTY_MESSAGE: {
    es_AR:
      'Todas las áreas de exclusión de tipo categoría "Otro" deben tener nombre. Debe guardar un nombre antes de continuar.',
    en_US: 'The exclusion areas of type other has no name. You must save a name before continuing.',
  },
  ERROR_CANNOT_EDIT_CATEGORY_WITH_DUPLICATES_NAMES_MESSAGE: {
    es_AR:
      'Posee dos o mas "%1" con el mismo nombre. Corrija el duplicado y vuelva a cargar el archivo.',
    en_US:
      'You have two or more "%1" with the same name. Correct the duplicate and reload the file.',
  },
  ERROR_CANNOT_EDIT_CATEGORY_WITH_DUPLICATES_NAMES_AND_TYPE_MESSAGE: {
    es_AR:
      'Posee dos o mas "%1" con el mismo nombre y tipo. Corrija el tipo o renombre antes de continuar',
    en_US:
      'You have two or more "%1" with the same name and type. Correct the type or rename before continuing',
  },
  FARM_SUBDIVISION_GEOMETRY_TYPE: {
    es_AR: 'Lotes',
    en_US: 'Allotments',
  },
  SAMPLING_AREA_GEOMETRY_TYPE: {
    es_AR: 'Estrato',
    en_US: 'Stratus',
  },
  MONITORING_SITE_GEOMETRY_TYPE: {
    es_AR: 'Sitio de monitoreo',
    en_US: 'Monitoring site',
  },
  UNSIGNED_POINT_GEOMETRY_TYPE: {
    es_AR: 'Punto sin asignar',
    en_US: 'Unsigned point',
  },
  EXCLUSION_AREA_GEOMETRY_TYPE: {
    es_AR: 'Área de exclusión',
    en_US: 'Exclusion area',
  },
  ERROR_INVALID_LAYER_NAME_MESSAGE: {
    es_AR:
      'Nombre inválido. Solo se permite un único espacio consecutivo, letras, números y los caracteres - _ ( ) /',
    en_US: 'Invalid name. Only letters, numbers and the characters - _ ( ) / are allowed',
  },
  ERROR_CANNOT_EDIT_FARM_PERIMETER_REQUIRED_MESSAGE: {
    es_AR: 'Es necesario cargar un perímetro. Corrija el duplicado y vuelva a cargar el archivo.',
    en_US: 'A perimeter is required. Correct the duplicate and reload the file.',
  },
  NO_PERMISSIONS_GRANTED: {
    es_AR: 'No dispone de los permisos para realizar dicha acción',
    en_US: "You don't have the permissions to perform this action",
  },
}
