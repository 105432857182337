/* eslint-disable no-shadow */
import { useAuth0 } from '@auth0/auth0-react'
import { Autocomplete, Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import AppContext from '../../context/appContext'
import lfStore from '../../lfstore/lfStore'
import { ruutsApi } from '../../services/ruutsApi'
import { processLoading } from '../../utils/Loading/processLoading'
import { messages } from '../../utils/messages/index.js'
import { displayErrorMessageModes } from '../GlobalLoadingModal/GlobalLoadingModal'

const FarmSelector = () => {
  const { setCurrentFarm, setProgramConfig, setLoadingModalConfig, userRoleSelected } =
    useContext(AppContext)
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0()
  const [assignedFarms, setAssignedFarms] = useState([])
  const navigate = useNavigate()

  const handleFarmSelect = async chosenFarm => {
    if (!chosenFarm) return

    await processLoading({
      getToken: getAccessTokenSilently,
      setLoadingModalConfig,
      loadingMessage: messages.getMessage(messages.keys.FORM_LOADING_FARM_MESSAGE),
      successfulMessage: '',
      contactReasonSupportType: {
        title: messages.getMessage(messages.keys.ERROR_FARM_WITH_MULTIPLE_DEALS_AND_PROGRAMS_TITLE),
        detail: messages.getMessage(
          messages.keys.ERROR_FARM_WITH_MULTIPLE_DEALS_AND_PROGRAMS_DETAIL,
        ),
      },
      displayErrorMessageMode: displayErrorMessageModes.dialog,
      errorMessage: messages.getMessage(
        messages.keys.ERROR_FARM_WITH_MULTIPLE_DEALS_AND_PROGRAMS_MESSAGE,
      ),
      displayErrorCustomMessage: false,
      doAction: async ({ token, _ }) => {
        let farm
        let exception
        try {
          farm = await ruutsApi.getFarm({ id: chosenFarm.id, user, token })
        } catch (error) {
          // If the farm has multiple deals and programs, we need to show a message to the user
          // so we load the farm and then we show the message. In this case, we need to load the farm
          // and the program config to show the message with farm and program data.
          // Otherwise, we show the error message without loading the farm and program data.
          if (
            error?.response?.error?.key === 'ERROR_FARM_WITH_MULTIPLE_DEALS_AND_PROGRAMS_MESSAGE'
          ) {
            farm = error.response
          }
          exception = error
        } finally {
          // This case if to load farm and program data when the farm is loaded successfully
          // and the farm has multiple deals and programs
          if (farm) {
            const programConfig = await ruutsApi.getProgramConfig(farm, token)
            const program = programConfig
              ? await ruutsApi.getProgram(programConfig.programId, token)
              : null

            await lfStore.setItem('currentFarm', farm)
            await lfStore.setItem('programConfig', programConfig)
            await lfStore.setItem('program', program)

            setCurrentFarm({
              ...farm,
              program,
            })
            setProgramConfig(programConfig)
          }
        }

        // if there was an exception, we throw it to show the error message properly by the processLoading function
        if (exception) throw exception

        // if the farm is valid, we navigate to the farms page
        navigate('/farms')
      },
    })
  }

  useEffect(() => {
    async function getFarmsByUserName(user) {
      await processLoading({
        getToken: getAccessTokenSilently,
        setLoadingModalConfig,
        loadingMessage: 'Cargando sus establecimientos asignados',
        successfulMessage: 'Establecimientos asignados cargados exitosamente',
        errorMessage: 'Error al cargar los establecimientos, por favor intente nuevamente',
        doAction: async ({ token }) => {
          let farms = await ruutsApi.getFarmsNameList({ user, token })
          if (farms && farms.length > 0) {
            farms = farms
              .map(farm => {
                const hub = farm.hub ? farm.hub : ''
                return {
                  id: farm.id,
                  key: farm.id,
                  name: farm.name,
                  group: `${farm.program} - ${hub}`,
                }
              })
              .sort((a, b) => {
                if (a.group < b.group) return -1
                if (a.group > b.group) return 1
                return 0
              })

            setAssignedFarms(farms)
          }
        },
      })
    }

    if (isAuthenticated && userRoleSelected) {
      getFarmsByUserName(user)
    }
  }, [user, userRoleSelected, isAuthenticated, getAccessTokenSilently, setLoadingModalConfig])

  return (
    <Stack alignItems="center" direction="column" display="flex" flexGrow={1} mt={8} spacing={3}>
      <Stack spacing={2} width="300px">
        <Stack>
          <Typography textAlign="center" variant="h6">
            <b>Establecimientos Asignados</b>
          </Typography>
          <Typography textAlign="center" variant="caption">
            Seleccione el establecimiento a trabajar
          </Typography>
        </Stack>
        {userRoleSelected && (
          <Autocomplete
            getOptionLabel={option => option.name}
            groupBy={option => option.group}
            id="farm-selector-autocomplete"
            options={assignedFarms}
            renderInput={params => (
              <TextField {...params} label="Establecimiento" variant="outlined" />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.key}>
                {option.name}
              </Box>
            )}
            style={{ width: 300 }}
            onChange={(event, newValue) => {
              handleFarmSelect(newValue)
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default FarmSelector
