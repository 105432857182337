import CloseIcon from '@mui/icons-material/Close'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Box, Button, Typography, styled } from '@mui/material'
import JSZip from 'jszip'
import { useCallback, useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import AppContext from '../../context/appContext'
import lfStore from '../../lfstore/lfStore'
import { isExtensionAccepted } from '../../utils/Files/fileHandle'
import { getFeaturesFromKML } from '../../utils/Files/importMapsHandle'
import { processLoadingWithConfirmation } from '../../utils/Loading/processLoading'
import { messages } from '../../utils/messages/index.js'
import { displayErrorMessageModes } from '../GlobalLoadingModal/GlobalLoadingModal'
import ExampleFileDownloader from './ExampleFileDownloader'
import { useViewOnly } from '../../hooks/useViewOnly.js'

const acceptedFiles = [
  'application/vnd.google-earth.kml+xml',
  'application/vnd.google-earth.kmz',
  'application/vnd.google-earth.kml',
]

const DISABLED_COLOR = '#bfbdc8'

const StyledFileContained = styled(Box)(() => ({
  '.title_file_container': {
    fontWeight: '500',
    fontSize: '.875em',
    textAlign: 'start',
  },
  '& .container': {
    fontSize: '1em',
    display: 'flex',
    paddingBottom: '2em',
    flexDirection: 'column',
    '& .MuiDialogContentText-root': {
      fontSize: 22,
      fontWeight: 300,
    },
    '.error_type_file': {
      fontSize: 12,
      display: 'flex',
      justifyContent: 'center',
      color: 'red',
    },
    '.description_content_file': {
      '.link_description_content_file': {
        color: '#1737B7',
        textDecoration: 'underline',
      },
      fontWeight: '400',
      fontSize: '.875em',
      color: '#767676',
      '.text_file_supported': {
        display: 'block',
      },
    },
  },
  '& .dropzone-enter': {
    margin: '10px',
    background: '#f3f3f3!important',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 300,
    borderRadius: 10,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
  },
  '& .dropzone': {
    margin: '10px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed #A5A5B3',
    fontWeight: 300,
    borderRadius: 10,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
  },
  '& .disabled': {
    cursor: 'no-drop',
    color: DISABLED_COLOR,
  },
}))

const DragDrop = ({
  handleEtapa,
  handleCenter,
  handlePerimeter,
  handlePaddocks,
  handleSamplingAreas,
  handleMonitoringSites,
  handleUnassigned,
  handleUnassignedPoints,
  handleExclusionAreas,
}) => {
  const [highlighted, setHighlighted] = useState(false)
  const [isValidate, setIsValidate] = useState(true)
  const [file, setFile] = useState(null)
  const {
    user,
    currentFarm,
    setPartialChanges,
    setConfirmationModalConfig,
    setLoadingModalConfig,
  } = useContext(AppContext)

  const { isViewOnly } = useViewOnly()

  const handleLayersToNull = () => {
    handlePerimeter(null)
    handlePaddocks(null)
    handleSamplingAreas(null)
    handleMonitoringSites(null)
    handleUnassigned(null)
    handleUnassignedPoints(null)
    handleExclusionAreas(null)
  }

  const handlePrintFile = async () => {
    handleLayersToNull()

    await processLoadingWithConfirmation({
      getToken: user.getAccessTokenSilently,
      setPartialChanges,
      confirmationModalConfig: {
        setConfirmationModalConfig,
        title: 'Confirmar Archivo',
        message:
          'Una vez confirmado, si el archivo posee un perímetro no podrá ser modificado. ¿Desea continuar?',
      },
      loadingModalConfig: {
        setLoadingModalConfig,
        loadingMessage: 'Cargando KML...',
        contactReasonSupportType: {
          title: messages.getMessage(messages.keys.ERROR_IMPORTING_FARM_FROM_KML_FILE_TITLE),
          detail: messages.getMessage(messages.keys.ERROR_IMPORTING_FARM_FROM_KML_FILE_DETAIL),
        },
        displayErrorMessageMode: displayErrorMessageModes.dialog,
        errorMessage: messages.getMessage(
          messages.keys.ERROR_IMPORTING_FARM_FROM_KML_FILE_FORM_MESSAGE,
          [file.name],
        ),
        successfulMessage: '',
      },
      doAction: async ({ _, dryRun }) => {
        if (dryRun) return // There is no need to do anything if it is a dry run because there there is no pre-validation to do

        getFeaturesFromKML(
          file.stringify,
          currentFarm,
          handleCenter,
          handlePerimeter,
          handlePaddocks,
          handleSamplingAreas,
          handleMonitoringSites,
          handleUnassigned,
          handleUnassignedPoints,
          handleExclusionAreas,
        )

        handleEtapa('showingFoldersArchivo')
        setPartialChanges(true)
      },
    })
  }

  const resetDragDrop = () => {
    setFile(null)
    handleEtapa('inicial')
  }

  const onDrop = useCallback(
    files => {
      setHighlighted(false)
      const newFile = files[0]

      // Caso de KMZ
      if (files[0].name.includes('.kmz')) {
        const reader = new FileReader()
        reader.onload = e => {
          const zip = new JSZip()
          zip.loadAsync(e.target.result).then(z => {
            z.file('doc.kml')
              .async('string')
              .then(kml => {
                setIsValidate(true)
                const fileObj = {
                  name: newFile.name,
                  stringify: kml,
                  size: (newFile.size / 1024).toFixed(2),
                  type: 'kml',
                }
                setFile(fileObj)
              })
          })
        }
        reader.readAsArrayBuffer(files[0])
        handleEtapa('fileUploaded')
        lfStore.setItem('storedMapeoDone', true)
      } else {
        // Caso de KML o GeoJSON
        // eslint-disable-next-line no-lonely-if
        if (acceptedFiles.includes(newFile.type) || isExtensionAccepted(newFile.name)) {
          setIsValidate(true)
          const reader = new FileReader()
          reader.onload = e => {
            const text = e.target.result
            const fileObj = {
              name: newFile.name,
              stringify: text,
              size: (newFile.size / 1024).toFixed(2),
              type: newFile.name.split('.').pop(),
            }
            setFile(fileObj)
          }
          reader.readAsText(newFile)
          handleEtapa('fileUploaded')
          lfStore.setItem('storedMapeoDone', true)
        } else {
          setIsValidate(false)
        }
      }
    },
    [handleEtapa],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter: () => {
      setHighlighted(true)
    },
    onDragLeave: () => {
      setHighlighted(false)
    },
    disabled: isViewOnly,
  })

  return (
    <StyledFileContained>
      {file ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <UploadFileIcon
              sx={{
                backgroundColor: '#e3eef9',
                color: '#1976D2',
                fontSize: 40,
                mx: 1,
                borderRadius: '100%',
                p: 1,
              }}
            />
            <Typography component="span" textAlign="center">
              {file.name}
            </Typography>
            <CloseIcon
              sx={{
                fontSize: 20,
                mx: 1,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={resetDragDrop}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: 12,
                textAlign: 'center',
                color: '#767676',
              }}
            >
              {file.size}kb - Complete
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              sx={{
                color: '#1976d2',
                backgroundColor: 'white',
                textTransform: 'none',
                fontWeight: 600,
                width: '95%',
                border: '1px solid #1976d2',
                alignSelf: 'center',
                mt: 2,
              }}
              onClick={() => {
                handlePrintFile()
              }}
            >
              Cargar y definir las áreas
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography
            component="span"
            sx={{
              fontSize: 14,
              m: 2,
              mt: 4,
            }}
          >
            Si ya posee un archivo con perímetros cárguelo aquí y confirme cada perímetro a la
            categoría que corresponda.
          </Typography>

          <ExampleFileDownloader />

          <div className="container">
            <div
              {...getRootProps({
                className: `${highlighted ? 'dropzone-enter' : 'dropzone'} ${isViewOnly && 'disabled'}`,
                id: 'dropzone',
              })}
            >
              <input {...getInputProps()} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 2,
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontSize: 18,
                    textDecoration: `${isViewOnly ? 'none' : 'underline'}`,
                    color: `${isViewOnly ? DISABLED_COLOR : '#1976D2'}`,
                    mr: 0.75,
                  }}
                >
                  Haga click para cargar
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontSize: 18,
                  }}
                >
                  o suelte el aquí
                </Typography>
              </Box>
              <Typography
                component="span"
                sx={{
                  fontSize: 14,
                  color: 'grey',
                  padding: 2,
                  paddingTop: 0,
                }}
              >
                KML/KMZ
              </Typography>
            </div>
            {!isValidate && (
              <div className="error_type_file">
                Tipo de archivo no soportado. Deber ser KML, KMZ.
              </div>
            )}
          </div>
        </Box>
      )}
    </StyledFileContained>
  )
}

export default DragDrop
