/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import { Fragment, useEffect, useState, useContext, useCallback } from 'react'
import { Box, Badge, Grid, Typography } from '@mui/material'

import { useAuth0 } from '@auth0/auth0-react'

import lfStore from '../../lfstore/lfStore'
import AppContext from '../../context/appContext'
import { mapActionsTypes } from './PerimeterMap.jsx'
import { updateFarmValidation } from '../../services/farmMapping/validateFarm.js'
import { getFarm } from '../../services/farmMapping/getFarms.js'

import { useProcessLoading } from '../../utils/Loading/useProcessLoading.js'
import { processLoadingWithConfirmation } from '../../utils/Loading/processLoading'
import {
  displayErrorMessageModes,
  contactSupportReasons,
} from '../GlobalLoadingModal/GlobalLoadingModal'
import { geoLocations } from '../../services/geoLocations'
import { Button } from '../Button/Button'
import SupportAlert from '../Alert/SupportAlert'
import { errors } from '../../services/ruutsApi/errors.js'

const requiredMinimumValidationPoints = 4
const maximumValidDistanceFromPerimeter = 10

const perimeterValidationStatuses = {
  Unvalidated: 1,
  Valid: 2,
  Validated: 3,
}

const VALID_GEOMETRY_TYPES = ['Polygon', 'MultiPolygon']

const PerimeterToValidate = ({
  overrideEdition,
  canValidate,
  isPerimeterValidated,
  setMapActions,
  setFeatures,
  featuresUpdated,
  mapReady,
}) => {
  const { user, getAccessTokenSilently } = useAuth0()
  const { setCurrentFarm, setLoadingModalConfig } = useContext(AppContext)
  const { setConfirmationModalConfig, setPartialChanges } = useContext(AppContext)
  const [validationPointsProcessed, setValidationPointsProcessed] = useState([])
  const [perimeterValidationStatus, setPerimeterValidationStatus] = useState(undefined)
  const { processLoading } = useProcessLoading()

  const validatePerimeter = (perimeter, validationPoints) => {
    return validationPoints.map(point => {
      const distanceFromPerimeter = Math.round(
        geoLocations.distanceOnPerimeter(point, perimeter).distance,
      )
      return {
        ...point,
        distanceFromPerimeter,
        isValidated: distanceFromPerimeter <= maximumValidDistanceFromPerimeter,
      }
    })
  }

  const handleValidatePerimeter = useCallback(
    features => {
      const perimeters = features.filter(feature =>
        VALID_GEOMETRY_TYPES.includes(feature.geometry.type),
      )
      if (perimeters?.length !== 1)
        throw new errors.BusinessEntityError({
          message:
            'No se puede validar el perímetro, cantidad capas en el perímetro no soportadas. Contactar soporte',
        })
      const perimeter = perimeters[0]

      const validationPoints = features.filter(feature => feature.geometry.type === 'Point')
      const processedValidationPoints = validatePerimeter(perimeter, validationPoints)
      const allPointsAreValidated =
        processedValidationPoints.filter(point => point.isValidated).length ===
        validationPoints.length
      const hasRequiredMinimumValidations =
        validationPoints.length >= requiredMinimumValidationPoints

      const newPerimeterValidationStatus = overrideEdition
        ? perimeterValidationStatuses.Valid
        : isPerimeterValidated
          ? perimeterValidationStatus.Validated
          : allPointsAreValidated && hasRequiredMinimumValidations
            ? perimeterValidationStatuses.Valid
            : perimeterValidationStatuses.Unvalidated

      perimeter.style = {
        color: [perimeterValidationStatuses.Valid, perimeterValidationStatuses.Validated].includes(
          newPerimeterValidationStatus,
        )
          ? 'green'
          : 'red',
      }
      const newValidationPointsProcessed = processedValidationPoints.map(point => {
        return {
          ...point,
          style: { color: point.isValidated ? 'green' : 'red' },
        }
      })

      setMapActions([
        {
          id:
            newPerimeterValidationStatus === perimeterValidationStatuses.Validated ||
            !canValidate.value
              ? mapActionsTypes.editEnd
              : mapActionsTypes.editStart,
          propertiesFeature: [{ id: perimeter.properties.id }],
        },
      ])
      setFeatures([perimeter, ...newValidationPointsProcessed])
      setValidationPointsProcessed(newValidationPointsProcessed)
      setPerimeterValidationStatus(newPerimeterValidationStatus)
      setPartialChanges(
        canValidate.status &&
          newPerimeterValidationStatus !== perimeterValidationStatuses.Validated,
      )

      return { status: newPerimeterValidationStatus, perimeter }
    },
    [
      canValidate.status,
      canValidate.value,
      isPerimeterValidated,
      perimeterValidationStatus,
      setFeatures,
      setMapActions,
      setPartialChanges,
      overrideEdition,
    ],
  )

  useEffect(() => {
    if (!mapReady || !featuresUpdated) return

    async function validate() {
      await processLoading({
        loadingMessage: 'Validando perímetro...',
        errorMessage: 'Error al validar el perímetro.',
        doAction: async () => {
          handleValidatePerimeter(featuresUpdated)
        },
      })
    }

    validate()
  }, [mapReady, featuresUpdated, canValidate, processLoading, handleValidatePerimeter])

  const saveValidatePerimeter = async ({ features, dryRun, user, overrideEdition, token }) => {
    const { status, perimeter } = handleValidatePerimeter(features)
    if (status !== perimeterValidationStatuses.Valid) return

    const farmId = perimeter.properties.id
    await updateFarmValidation({
      id: farmId,
      geometry: perimeter.geometry,
      originalGeometry: perimeter.properties.originalGeometry,
      dryRun,
      overrideEdition,
      token,
    })
    const farm = await getFarm({ id: farmId, user, token })

    lfStore.setItem('currentFarm', farm)
    setCurrentFarm(farm)

    if (farm.isPerimeterValidated) {
      setMapActions([
        {
          id: mapActionsTypes.editEnd,
          propertiesFeature: [{ id: perimeter.properties.id }],
        },
      ])
    }
  }

  const handleSaveValidatePerimeter = async features => {
    await processLoadingWithConfirmation({
      getToken: getAccessTokenSilently,
      setPartialChanges,
      loadingModalConfig: {
        setLoadingModalConfig,
        loadingMessage: 'Validando perímetro...',
        contactReasonSupportType: contactSupportReasons.PerimeterValidationsCouldNotValidate,
        errorMessage: 'Error al validar el perímetro, por favor intente nuevamente',
        displayErrorMessageMode: displayErrorMessageModes.dialog,
        successfulMessage: '',
      },
      confirmationModalConfig: {
        setConfirmationModalConfig,
        title: 'Confirmar validación de perímetro',
        message:
          'Una vez hecha la validación no se podrá modificar el perímetro. ¿Desea continuar?',
      },
      doAction: async ({ token, dryRun }) => {
        await saveValidatePerimeter({ dryRun, features, user, overrideEdition, token })
      },
    })
  }

  return (
    <>
      {canValidate && (
        <Grid container>
          {!canValidate.value ? (
            <Grid item sx={{ paddingTop: '20px', textAlign: 'center' }} xs={12}>
              <SupportAlert
                contactSupportReasonType={canValidate.contactSupportReasonType}
                error={canValidate.error}
                message={canValidate.displayError}
              />
            </Grid>
          ) : (
            <>
              <Grid item sx={{ padding: '20px', textAlign: 'center' }} xs={12}>
                <Typography variant="p">
                  Los puntos resaltados en rojo deben mantenerse a una distancia máxima de{' '}
                  {maximumValidDistanceFromPerimeter} metros del perímetro. Por favor, ajuste las
                  dimensiones del perímetro hasta que todos los puntos estén a una distancia de
                  hasta {maximumValidDistanceFromPerimeter} metros y se muestren en verde.
                </Typography>
              </Grid>
              {validationPointsProcessed.length > 0 &&
                validationPointsProcessed.map((point, index) => {
                  return (
                    <Fragment key={index}>
                      <Grid item xs={8}>
                        <Typography sx={{ fontSize: 16 }}>{point.properties.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Badge
                          badgeContent={`${point.distanceFromPerimeter.toFixed()} m`}
                          color={point.isValidated ? 'success' : 'error'}
                          sx={{
                            '& .MuiBadge-badge': {
                              backgroundColor: point.style.color,
                              color: 'white',
                              fontSize: '0.8rem',
                              fontWeight: 'bold',
                              minWidth: '70px',
                              minHeight: '20px',
                              padding: '0 0px',
                              right: '0px',
                            },
                          }}
                        />
                      </Grid>
                    </Fragment>
                  )
                })}
              <Grid item xs={12}>
                <Box sx={{ padding: '20px', textAlign: 'center' }}>
                  <Button
                    disabled={
                      !canValidate.value ||
                      perimeterValidationStatus !== perimeterValidationStatuses.Valid
                    }
                    showTooltip={canValidate.reason}
                    tooltipTitle={canValidate.reason}
                    onClick={() => {
                      handleSaveValidatePerimeter(featuresUpdated)
                    }}
                  >
                    Validar perímetro
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  )
}

export default PerimeterToValidate
