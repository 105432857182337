import Tooltip from '@mui/material/Tooltip'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { IconButton, Stack } from '@mui/material'

const TableDocumentsButtons = ({ recordData, handleEditDocuments, disabled = false }) => {
  return (
    <Stack alignItems="center" direction="row" display="flex" justifyContent="center">
      <Tooltip title="Documentos">
        <span>
          <IconButton
            disabled={disabled}
            size="small"
            onClick={() => handleEditDocuments(recordData)}
          >
            <NoteAddIcon sx={{ height: '18px', width: '18px' }} />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  )
}

export default TableDocumentsButtons
