/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import L from 'leaflet'
import markerIconPngBlue from 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/leaflet.css'
import { useEffect, useState } from 'react'
import {
  FeatureGroup,
  GeoJSON,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  Pane,
  Tooltip,
} from 'react-leaflet'
import { getRandomColor } from '../../utils/mapDrawHandle'
import markerIconShadow from '../../utils/marker-icons/marker-shadow.png'
import BaseLayers from '../map/BaseLayers'

const SamplePlanningMap = ({
  stratas,
  monitoringSitesBySamplingAreas,
  mapWidth,
  mapHeight,
  perimeter,
  layers = [],
}) => {
  const [map, setMap] = useState()

  const handleStyle = feature => {
    return {
      fillColor: feature.properties.color,
      color: 'black',
      weight: 1,
      opacity: 1,
      fillOpacity: 0.6,
    }
  }

  useEffect(() => {
    if (map && stratas) {
      L.geoJSON(perimeter, {
        style: {
          color: 'black',
          weight: 3,
          opacity: 1,
          fillOpacity: 0,
        },
      }).addTo(map)

      map.fitBounds(L.geoJSON(stratas).getBounds(), { padding: [50, 50] })
    }
  }, [map, perimeter, stratas])

  const getColor = layer => {
    if (layer.name.includes('Loteo')) {
      return getRandomColor()
    }

    return layer.features[0]?.properties?.color
      ? layer.features[0]?.properties?.color
      : getRandomColor()
  }

  const handleMouseOver = event => {
    const { layer } = event
    const tooltipContent = layer?.feature?.properties?.name
    layer.bindTooltip(tooltipContent).openTooltip()
  }

  return (
    <Container disableGutters sx={{ width: '100%' }}>
      <MapContainer
        ref={setMap}
        scrollWheelZoom
        zoomControl
        attributionControl={false}
        closePopupOnClick={false}
        style={{ height: mapHeight - 100, width: mapWidth }}
        wheelPxPerZoomLevel={60}
        zoom={5}
        zoomDelta={1}
        zoomSnap={0.1}
      >
        <LayersControl collapsed={false}>
          <BaseLayers />
          {layers.map(layer => (
            <Pane key={layer.name} name={layer.name}>
              <LayersControl.Overlay name={layer.name}>
                <FeatureGroup>
                  <LayerGroup>
                    <GeoJSON
                      data={layer.features}
                      eventHandlers={{
                        mouseover: handleMouseOver,
                      }}
                      style={() => {
                        return {
                          color: getColor(layer),
                          weight: 2,
                          opacity: 1,
                          fillOpacity: 0.4,
                        }
                      }}
                    />
                  </LayerGroup>
                </FeatureGroup>
              </LayersControl.Overlay>
            </Pane>
          ))}
        </LayersControl>
        <FeatureGroup>
          <LayerGroup>
            {stratas && (
              <GeoJSON
                data={stratas}
                style={handleStyle}
                onEachFeature={(feature, layer) => {
                  layer.bindTooltip(feature.properties.name, {
                    permanent: false,
                    direction: 'left',
                    sticky: true,
                    className: 'strata-label',
                  })
                }}
              />
            )}
          </LayerGroup>
        </FeatureGroup>
        {monitoringSitesBySamplingAreas &&
          stratas &&
          stratas.features.map((strata, index) => {
            return (
              <FeatureGroup key={index}>
                <LayerGroup>
                  {monitoringSitesBySamplingAreas[strata.properties.name] &&
                    monitoringSitesBySamplingAreas[strata.properties.name].map((marker, index) => {
                      if (marker.properties.isRandomSite) {
                        return (
                          <Marker
                            key={index}
                            icon={L.divIcon({
                              className: 'my-div-icon',
                              html: `<span style="background-color: ${strata.properties.color}; filter: saturate(2.5);  border-radius: 50%; border: 2px solid #000; box-shadow: rgba(0,0,0,.5) 1px 1px 3px; width: 100%; height: 100%; display: inline-block; transform: scale(0.8);"></span>`,
                            })}
                            position={[
                              marker.geometry.coordinates[1],
                              marker.geometry.coordinates[0],
                            ]}
                          >
                            <Tooltip>
                              <div>
                                <Typography variant="p">{marker.properties.name}</Typography>
                              </div>
                            </Tooltip>
                          </Marker>
                        )
                      }
                      return (
                        <Marker
                          key={index}
                          icon={L.icon({
                            iconUrl: markerIconPngBlue,
                            shadowUrl: markerIconShadow,
                            iconSize: [25, 41],
                            iconAnchor: [12, 41],
                          })}
                          opacity={0.8}
                          position={[
                            marker.geometry.coordinates[1],
                            marker.geometry.coordinates[0],
                          ]}
                        >
                          <Tooltip>
                            <div>
                              <Typography variant="p">{marker.properties.name}</Typography>
                            </div>
                          </Tooltip>
                        </Marker>
                      )
                    })}
                </LayerGroup>
              </FeatureGroup>
            )
          })}
      </MapContainer>
    </Container>
  )
}

export default SamplePlanningMap
