import endpoints from '../ruutsApi/endpoints'
import { postApiDataWithPayloadResponse } from '../ruutsApi/postApiData'

export const saveExclusionAreas = async (currentFarm, exclusionAreas, token) => {
  const exclusionAreasBody = exclusionAreas.map(exclusionArea => ({
    name: exclusionArea.properties.name,
    otherName: exclusionArea.properties.name,
    farmId: currentFarm.id,
    geometry: exclusionArea.geometry,
    exclusionAreaTypeId: exclusionArea?.properties?.exclusionAreaTypeId,
    hasGrazingManagement: exclusionArea.properties.hasGrazingManagement,
    color: 'hsl(40, 60%, 0%)',
  }))
  return postApiDataWithPayloadResponse(endpoints.exclusionAreas, exclusionAreasBody, token)
}
