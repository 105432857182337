import AddIcon from '@mui/icons-material/Add'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import { Select } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../../context/appContext'
import {
  deleteExclusionAreas,
  deleteExclusionAreasByFarm,
} from '../../../services/farmMapping/exclusionAreas/deleteExclusionAreas.js'
import { getExclusionAreasByFarm } from '../../../services/farmMapping/exclusionAreas/getExclusionAreas.js'
import {
  createExclusionAreasFeatureByFarm,
  updateExclusionAreasFeatureByFarm,
} from '../../../services/farmMapping/exclusionAreas/saveExclusionAreas.js'
import { useProcessLoading } from '../../../utils/Loading/useProcessLoading'
import { messages } from '../../../utils/messages/index.js'
import { useCustomSnackbarError } from '../../../utils/Snackbar/useCustomSnackbarError.js'
import { LoadingButton } from '../../Button/LoadingButton'
import ClassificationButtons from '../ClasificationPanel'
import ClassificationCreation, { BackButton } from '../Creation'

const ExclusionAreaPanel = ({
  exclusionAreas,
  setExclusionAreas,
  exclusionAreasFeatures,
  setExclusionAreasFeatures,
  exclusionTypes,
  farm,
  wasSaved,
  setWasSaved,
  georaster,
  setGeoraster,
  drawActivated,
  setDrawActivated,
  setMapAction,
  mapAction,
  setSelectedArea,
  selectedArea,
  setSelectedAreaType,
  setVectorized,
  restratification,
  setRestratification,
  canEditClasification,
  setTabValue,
  supportedCreationModes,
}) => {
  const [loading, setLoading] = useState(true)
  const { setPartialChanges, setHideNavbar } = useContext(AppContext)
  const [disableActionReason, setDisableActionReason] = useState(null)
  const { processLoading } = useProcessLoading()
  const { handleError } = useCustomSnackbarError()

  function resetControl() {
    setVectorized(false)
    setWasSaved(true)
    setDrawActivated(false)
    setPartialChanges(false)
    setHideNavbar(false)
    setLoading(false)
  }

  async function reloadExclusionAreas() {
    await processLoading({
      loadingMessage: 'Cargando áreas de exclusión...',
      errorMessage: 'Error al cargar áreas de exclusión.',
      doAction: async ({ token }) => {
        const newExclusionAreas = await getExclusionAreasByFarm({ farmId: farm.id, token })
        setExclusionAreas(newExclusionAreas)
        const newExclusionAreasFeatures = newExclusionAreas.map(
          exclusionArea => exclusionArea.toGeoJSON,
        )
        setExclusionAreasFeatures(newExclusionAreasFeatures)
        resetControl()
      },
    })
  }

  const saveStratification = async () => {
    await processLoading({
      loadingMessage: 'Creando áreas de exclusión...',
      errorMessage: 'Error al crear áreas de exclusión.',
      doAction: async ({ token }) => {
        await createExclusionAreasFeatureByFarm({
          farmId: farm.id,
          features: exclusionAreasFeatures,
          exclusionTypes,
          token,
        })
        reloadExclusionAreas()
      },
    })
  }

  const updateStratification = async () => {
    await processLoading({
      loadingMessage: 'Actualizando áreas de exclusión...',
      errorMessage: 'Error al actualizar áreas de exclusión.',
      doAction: async ({ token }) => {
        const exclusionAreasIdsToDelete = exclusionAreas.filter(exclusionArea => {
          return !exclusionAreasFeatures.find(
            exclusion => exclusionArea.id === exclusion.properties.id,
          )
        })

        const exclusionAreasToUpdate = exclusionAreasFeatures.filter(
          exclusion => exclusion.properties.id,
        )
        const exclusionAreasToCreate = exclusionAreasFeatures.filter(
          exclusion => !exclusion.properties.id,
        )

        if (exclusionAreasIdsToDelete.length) {
          await deleteExclusionAreas({ entities: exclusionAreasIdsToDelete, token })
        }

        if (exclusionAreasToUpdate.length) {
          await updateExclusionAreasFeatureByFarm({
            farmId: farm.id,
            features: exclusionAreasToUpdate,
            token,
            exclusionTypes,
          })
        }

        if (exclusionAreasToCreate.length) {
          await createExclusionAreasFeatureByFarm({
            farmId: farm.id,
            features: exclusionAreasToCreate,
            exclusionTypes,
            token,
          })
        }
        reloadExclusionAreas()
      },
    })
  }

  useEffect(() => {
    if (mapAction === 'edit') {
      setDisableActionReason(
        messages.getMessage(messages.keys.ERROR_CANNOT_EDIT_GEOMETRY_APPLY_CHANGES_MESSAGE),
      )
      return
    }

    if (!exclusionAreasFeatures?.length) {
      setDisableActionReason(
        messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_EXCLUSION_AREAS_EMPTY_GEOMETRIES_MESSAGE,
        ),
      )
      return
    }

    if (exclusionAreasFeatures?.some(s => s.properties.name === '')) {
      setDisableActionReason(
        messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_EXCLUSION_AREA_EMPTY_GEOMETRY_NAME_MESSAGE,
        ),
      )
      return
    }

    setDisableActionReason(null)
  }, [exclusionAreasFeatures, mapAction, setDisableActionReason])

  useEffect(() => {
    if (exclusionAreas) {
      const features = exclusionAreas.map(exclusionArea => exclusionArea.toGeoJSON)
      setExclusionAreasFeatures(features)
      setWasSaved(features.length > 0)
      setLoading(false)
    }
  }, [setExclusionAreasFeatures, exclusionAreas, setWasSaved])

  return (
    <>
      {(loading || !farm) && (
        <Stack
          sx={{
            height: drawActivated ? '75vh' : '85vh',
            overflowY: 'scroll',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Stack>
      )}
      {!loading && farm && (
        <Stack sx={{ height: drawActivated ? '75vh' : '85vh', overflowY: 'scroll' }}>
          {!drawActivated && wasSaved && (
            <ClassificationButtons
              canEditClasification={canEditClasification}
              clasificationAlreadyCreatedMessage="Ya se ha realizado la generación de área de exclusión del establecimiento."
              clasificationType="Área de exclusión"
              deleteClasification={async () => {
                await processLoading({
                  loadingMessage: 'Borrando áreas de exclusión...',
                  errorMessage: 'Error al borrar las áreas de exclusión.',
                  doAction: async ({ token }) => {
                    await deleteExclusionAreasByFarm({ farmId: farm.id, token })
                    await reloadExclusionAreas()
                  },
                })
              }}
              deleteMessageConfirmation="Esta a punto de eliminar todas las áreas de exclusión creadas hasta el momento y no se van a poder recuperar. ¿Desea continuar?"
              editMessageButton="Editar área de exclusión"
              nextStepMessage="Continuar con la estratificación"
              nextStepTabIndex={1}
              setDrawActivated={setDrawActivated}
              setLoading={setLoading}
              setRestratification={setRestratification}
              setTabValue={setTabValue}
              setWasSaved={setWasSaved}
            >
              Borrar áreas de exclusión
            </ClassificationButtons>
          )}
          {!drawActivated && !wasSaved && (
            <ClassificationCreation
              georaster={georaster}
              perimeter={farm.toGeoJSON}
              setDrawActivated={setDrawActivated}
              setGeoraster={setGeoraster}
              setVectorized={setVectorized}
              supportedCreationModes={supportedCreationModes}
              type="ExclusionArea"
            />
          )}
          {drawActivated && !wasSaved && (
            <>
              <BackButton
                reloadFeatures={() => reloadExclusionAreas()}
                setDrawActivated={setDrawActivated}
                setMapAction={setMapAction}
                setWasSaved={setWasSaved}
              />
              <Grid container sx={{ padding: '0 10px' }}>
                <Grid item sx={{ textAlign: 'center' }} xs={12}>
                  <Box sx={{ padding: '10px', textAlign: 'center' }}>
                    <Button
                      disabled={mapAction === 'edit'}
                      size="small"
                      startIcon={<AddIcon />}
                      variant="outlined"
                      onClick={() => {
                        setSelectedArea(null)
                        setSelectedAreaType('exclusion')
                        setMapAction('draw')
                      }}
                    >
                      Crear Área de exclusion
                    </Button>
                  </Box>
                </Grid>
                {exclusionAreasFeatures &&
                  exclusionTypes &&
                  exclusionAreasFeatures.map((exclusion, index) => {
                    return (
                      <Grid
                        key={exclusion.properties.id || exclusion.properties.tmpId}
                        item
                        xs={12}
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{
                            alignItems: 'center',
                            marginTop: '10px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            sx={{
                              width: '20px',
                              minWidth: '20px',
                              height: '20px',
                              borderRadius: '50%',
                              backgroundColor: '#000',
                            }}
                            onClick={() => {
                              setExclusionAreasFeatures(prev =>
                                prev.map((exclusionArea, indexPrev) => ({
                                  ...exclusionArea,
                                  properties: {
                                    ...exclusionArea.properties,
                                    selected: indexPrev === index,
                                  },
                                })),
                              )
                            }}
                          />
                          <FormControl size="small" sx={{ m: 1, minWidth: 100 }}>
                            <InputLabel id="exclusion-category-label">Categoría</InputLabel>
                            <Select
                              autoWidth
                              error={exclusion.properties.exclusionAreaTypeId === ''}
                              id="exclusion-category-select"
                              label="Categoría"
                              labelId="exclusion-category-label"
                              value={exclusion.properties.exclusionAreaTypeId}
                              onChange={e => {
                                setExclusionAreasFeatures(prev => {
                                  const newExclusion = [...prev]
                                  newExclusion[index].properties.exclusionAreaTypeId =
                                    e.target.value
                                  newExclusion[index].properties.name = exclusionTypes.find(
                                    exclusionType => exclusionType.id === e.target.value,
                                  ).es_AR
                                  return newExclusion
                                })
                                setPartialChanges(true)
                              }}
                            >
                              {exclusionTypes.map(type => {
                                return (
                                  <MenuItem key={type.id} value={type.id}>
                                    {type.es_AR}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                          <FormControl size="small" sx={{ m: 1, minWidth: 80 }}>
                            <TextField
                              defaultValue={exclusion.properties.otherName}
                              label="Nombre"
                              size="small"
                              variant="outlined"
                              onChange={e => {
                                setExclusionAreasFeatures(prev => {
                                  const newExclusion = [...prev]
                                  newExclusion[index].properties.otherName = e.target.value
                                  return newExclusion
                                })
                              }}
                            />
                          </FormControl>
                          <FormControl
                            component="fieldset"
                            size="small"
                            sx={{ m: 1, minWidth: 50 }}
                          >
                            <Box alignItems="center" display="flex" flexDirection="column">
                              <Checkbox
                                checked={exclusion.properties.hasGrazingManagement}
                                name="hasGrazingManagement"
                                onChange={e => {
                                  setExclusionAreasFeatures(prev => {
                                    const newExclusion = [...prev]
                                    newExclusion[index].properties.hasGrazingManagement =
                                      e.target.checked
                                    return newExclusion
                                  })
                                }}
                              />
                              <FormLabel
                                component="legend"
                                style={{ textAlign: 'center', width: '100%' }}
                              >
                                Con pastoreo
                              </FormLabel>
                            </Box>
                          </FormControl>
                          <Typography variant="p">
                            {exclusion.properties?.area?.toFixed(2)} has
                          </Typography>
                          <Stack direction="row" spacing={0} sx={{ alignItems: 'center' }}>
                            <IconButton
                              color="primary"
                              disabled={mapAction === 'edit' && selectedArea !== exclusion}
                              size="small"
                              sx={{
                                display:
                                  mapAction === 'edit' && selectedArea === exclusion
                                    ? 'none'
                                    : 'initial',
                              }}
                              variant="contained"
                              onClick={() => {
                                setSelectedArea(exclusion)
                                setSelectedAreaType('exclusion')
                                setMapAction('draw')
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                            {mapAction === 'edit' && selectedArea === exclusion ? (
                              <>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    setSelectedArea(exclusion)
                                    setSelectedAreaType('exclusion')
                                    setMapAction('done')
                                  }}
                                >
                                  <DoneIcon />
                                </IconButton>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    setSelectedArea(exclusion)
                                    setSelectedAreaType('exclusion')
                                    setMapAction(null)
                                    setPartialChanges(false)
                                  }}
                                >
                                  <ClearOutlinedIcon />
                                </IconButton>
                              </>
                            ) : (
                              <IconButton
                                color="primary"
                                disabled={mapAction === 'edit' && selectedArea !== exclusion}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  setSelectedArea(exclusion)
                                  setSelectedAreaType('exclusion')
                                  setMapAction('edit')
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                            <IconButton
                              color="error"
                              disabled={mapAction === 'edit' && selectedArea !== exclusion}
                              size="small"
                              sx={{
                                display:
                                  mapAction === 'edit' && selectedArea !== exclusion
                                    ? 'none'
                                    : 'initial',
                              }}
                              variant="contained"
                              onClick={() => {
                                setExclusionAreasFeatures(prev => {
                                  if (prev.length > 1) {
                                    const newExclusion = prev.filter(s => s !== exclusion)
                                    return newExclusion
                                  }
                                  handleError(
                                    null,
                                    "No es posible eliminar todas las áreas de exclusión desde este menú. Para hacerlo, vuelva a la pantalla de Monitoreo y haga clic en 'Borrar áreas de exclusión'",
                                    false,
                                  )
                                  return prev
                                })
                                setMapAction('remove')
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Grid>
                    )
                  })}
              </Grid>
            </>
          )}
        </Stack>
      )}

      {drawActivated && exclusionAreasFeatures && !wasSaved && !restratification && (
        <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'center' }}>
          <LoadingButton
            disableActionReason={disableActionReason}
            loading={loading}
            onClick={saveStratification}
          >
            Guardar
          </LoadingButton>
        </Box>
      )}

      {drawActivated && exclusionAreasFeatures && !wasSaved && restratification && (
        <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'center' }}>
          <LoadingButton
            disableActionReason={disableActionReason}
            loading={loading}
            onClick={updateStratification}
          >
            Actualizar área de exclusión
          </LoadingButton>
        </Box>
      )}
    </>
  )
}

export default ExclusionAreaPanel
