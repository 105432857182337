/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Box, Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { georaster as georasterService } from '../../services/ruutsServices/georaster/index'
import { GeoJSON } from '../../utils/GeoJSON/index'
import { useProcessLoading } from '../../utils/Loading/useProcessLoading'
import { generateSamplingAreasFromGeoJSON } from './AutomaticClasification'
import { useViewOnly } from '../../hooks/useViewOnly'

const DropTiff = ({
  title,
  georaster,
  setGeoraster,
  setSamplingAreasFeatures,
  setDrawActivated,
  setVectorized,
  threshold,
  perimeter,
}) => {
  const [highlighted, setHighlighted] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [files, setFiles] = useState()
  const { processLoading } = useProcessLoading()
  const { isViewOnly } = useViewOnly()

  const handleVectorize = async () => {
    await processLoading({
      loadingMessage: 'Generando estratificación semi-automática...',
      errorMessage:
        'Error al generar la estratificación semi-automática. Por favor, intenta nuevamente.',
      doAction: async ({ token }) => {
        if (!threshold) {
          threshold = 30
        }
        const farmArea = GeoJSON.hectareArea(perimeter)
        const vectorizeResult = await georasterService.vectorize(
          files[0],
          georaster.name,
          perimeter,
          farmArea,
          threshold,
          token,
        )

        const stratas = generateSamplingAreasFromGeoJSON(vectorizeResult)

        setSamplingAreasFeatures(stratas)
        setDrawActivated(true)
        setVectorized(true)
        setGeoraster(null)
      },
    })
  }

  const onDrop = useCallback(
    files => {
      setHighlighted(false)
      const newFile = files[0]
      setFiles(files)

      // check if file is a geotiff
      if (files[0].type === 'image/tiff') {
        const reader = new FileReader()
        reader.readAsArrayBuffer(newFile)
        reader.onload = e => {
          const fileObj = {
            name: newFile.name,
            size: newFile.size,
            type: newFile.type,
            data: e.target.result,
          }

          setGeoraster(fileObj)
          setIsValid(true)
          return fileObj
        }
      } else {
        setIsValid(false)
      }
    },
    [setHighlighted, setFiles, setGeoraster, setIsValid],
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          width: '100%',
          height: '150px',
          border: '1px dashed',
          borderColor: highlighted ? 'primary.main' : 'grey.500',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': {
            borderColor: 'primary.main',
          },
        }}
        onDragEnter={() => {
          setHighlighted(true)
        }}
        onDragLeave={() => {
          setHighlighted(false)
        }}
      >
        <input {...getInputProps()} />
        {!georaster && (
          <Stack alignItems="center" direction="column" justifyContent="center">
            <Typography variant="h6">{title}</Typography>
            <Stack alignItems="center" direction="row" sx={{ mt: 2 }}>
              <UploadFileIcon sx={{ mr: 1, fontSize: '30px' }} />
              <Typography variant="p">Click aquí o suelta el archivo TIFF</Typography>
            </Stack>
          </Stack>
        )}

        {georaster && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Box sx={{ mr: 1 }}>
                <Typography sx={{ lineBreak: 'anywhere' }} variant="p">
                  {georaster.name}
                </Typography>
              </Box>
              <Box sx={{ mr: 1 }}>
                <Typography variant="p">{georaster.size}kb</Typography>
              </Box>
            </Box>
            <CloseIcon
              sx={{ ml: 1 }}
              onClick={e => {
                e.stopPropagation()
                setGeoraster(null)
              }}
            />
          </Box>
        )}
      </Box>
      {files && !isValid && (
        <Typography color="error" variant="p">
          El archivo no es un TIFF
        </Typography>
      )}
      {georaster && isValid && (
        <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'center' }}>
          <Button
            color="primary"
            disabled={isViewOnly}
            endIcon={<CheckCircleIcon />}
            variant="contained"
            onClick={handleVectorize}
          >
            Cargar archivo
          </Button>
        </Box>
      )}
    </>
  )
}

export default DropTiff
