import endpoints from '../../ruutsApi/endpoints'
import deleteApiData from '../../ruutsApi/deleteApiData'

export const deleteExclusionAreas = async ({ entities, token }) => {
  const ids = entities.map(entity => entity.id)

  return deleteApiData(`${endpoints.exclusionAreas}`, { ids }, token)
}

export const deleteExclusionAreasByFarm = async ({ farmId, token }) =>
  deleteApiData(`${endpoints.exclusionAreas}/farms/${farmId}`, null, token)
