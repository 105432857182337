import endpoints from '../../ruutsApi/endpoints'
import deleteApiData from '../../ruutsApi/deleteApiData'

export const deleteSamplingAreas = async ({ entities, token }) => {
  const ids = entities.map(entity => entity.id)

  return deleteApiData(`${endpoints.samplingAreas}`, { ids }, token)
}

export const deleteSamplingAreasByFarm = async ({ farmId, token }) =>
  deleteApiData(`${endpoints.samplingAreas}/farms/${farmId}`, null, token)
