import { Box, Button, Typography } from '@mui/material'
import lfStore from '../../../lfstore/lfStore'
import { useViewOnly } from '../../../hooks/useViewOnly'

const ManualMapeoHeader = ({ handleEtapa }) => {
  const { isViewOnly } = useViewOnly()

  const handleManualMapeo = () => {
    handleEtapa('inicialManual')
    lfStore.setItem('storedMapeoDone', true)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 3,
      }}
    >
      <Typography
        component="span"
        sx={{
          fontSize: 14,
          margin: 2,
          marginTop: 4,
        }}
      >
        Dibuje el perímetro del establecimiento marcando los puntos que lo definen.
      </Typography>
      <Button
        disabled={isViewOnly}
        sx={{
          color: '#1976d2',
          backgroundColor: 'white',
          textTransform: 'none',
          fontWeight: 600,
          width: '95%',
          border: '1px solid #1976d2',
          alignSelf: 'center',
        }}
        onClick={handleManualMapeo}
      >
        Crear perímetro
      </Button>
    </Box>
  )
}

export default ManualMapeoHeader
