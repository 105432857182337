/* eslint-disable react/no-array-index-key */
import { useAuth0 } from '@auth0/auth0-react'
import AddIcon from '@mui/icons-material/Add'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../../context/appContext'
import { ruutsApi } from '../../../services/ruutsApi'
import { GeoJSON } from '../../../utils/GeoJSON/index'
import { useProcessLoading } from '../../../utils/Loading/useProcessLoading'
import { miniumFeatureHectareAllowed } from '../../../utils/mapDrawHandle'
import { messages } from '../../../utils/messages/index.js'
import { LoadingButton } from '../../Button/LoadingButton'
import ClassificationButtons from '../ClasificationPanel'
import ClassificationCreation, { BackButton } from '../Creation'

const StratificationPanel = ({
  samplingAreas,
  setSamplingAreas,
  samplingAreasFeatures,
  setSamplingAreasFeatures,
  setSamplingAreasSaved,
  farm,
  perimeter,
  wasSaved,
  setWasSaved,
  georaster,
  setGeoraster,
  drawActivated,
  setDrawActivated,
  setMapAction,
  mapAction,
  setSelectedArea,
  selectedArea,
  setSelectedAreaType,
  setVectorized,
  restratification,
  setRestratification,
  canEditClasification,
  setTabValue,
  supportedCreationModes,
  previewSampling,
  setPreviewSampling,
}) => {
  const [loading, setLoading] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const { setPartialChanges, setHideNavbar } = useContext(AppContext)
  const [disableActionReason, setDisableActionReason] = useState(null)
  const { processLoading } = useProcessLoading()

  function reloadFeatures(sAreas) {
    setSamplingAreas(sAreas)
    setSamplingAreasFeatures(() => sAreas.map(samplingArea => samplingArea.toGeoJSON))
    setSamplingAreasSaved(sAreas.length > 0)
  }

  async function loadSamplingAreas() {
    const token = await getAccessTokenSilently()
    const newSamplingAreas = await ruutsApi.samplingAreas.getUncroppedByFarm({
      farmId: farm.id,
      token,
    })
    reloadFeatures(newSamplingAreas)
  }

  function resetControl() {
    setVectorized(false)
    setWasSaved(true)
    setDrawActivated(false)
    setPartialChanges(false)
    setHideNavbar(false)
    setLoading(false)
  }

  const saveStratification = async () => {
    setLoading(true)
    const token = await getAccessTokenSilently()

    await ruutsApi.samplingAreas.createByFarm({
      farmId: farm.id,
      features: samplingAreasFeatures,
      token,
    })

    loadSamplingAreas()
    resetControl()
  }

  const updateStratification = async () => {
    setLoading(true)
    const token = await getAccessTokenSilently()

    const samplingAreasToDelete = samplingAreas.filter(samplingArea => {
      return !samplingAreasFeatures.find(strata => samplingArea.id === strata.properties.id)
    })
    const samplingAreasToUpdate = samplingAreasFeatures.filter(strata => strata.properties.id)
    const samplingAreasToCreate = samplingAreasFeatures.filter(strata => !strata.properties.id)

    if (samplingAreasToDelete.length > 0) {
      await ruutsApi.samplingAreas.deleteEntities({ entities: samplingAreasToDelete, token })
    }
    if (samplingAreasToUpdate.length > 0) {
      await ruutsApi.samplingAreas.updateByFarm({
        farmId: farm.id,
        features: samplingAreasToUpdate,
        token,
      })
    }
    if (samplingAreasToCreate.length > 0) {
      await ruutsApi.samplingAreas.createByFarm({
        farmId: farm.id,
        features: samplingAreasToCreate,
        token,
      })
    }

    loadSamplingAreas()
    resetControl()
  }

  useEffect(() => {
    if (!samplingAreas) return

    const features = samplingAreas.map(samplingArea => samplingArea.toGeoJSON)
    setSamplingAreasFeatures(features)
  }, [samplingAreas, setSamplingAreasFeatures])

  useEffect(() => {
    if (mapAction === 'edit') {
      setDisableActionReason(
        messages.getMessage(messages.keys.ERROR_CANNOT_EDIT_GEOMETRY_APPLY_CHANGES_MESSAGE),
      )
      return
    }

    if (samplingAreasFeatures.length === 0) {
      setDisableActionReason(
        messages.getMessage(messages.keys.ERROR_CANNOT_EDIT_SAMPLING_AREA_EMPTY_GEOMETRIES_MESSAGE),
      )
      return
    }

    if (samplingAreasFeatures.some(s => s.properties.name === '')) {
      setDisableActionReason(
        messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_SAMPLING_AREA_EMPTY_GEOMETRY_NAME_MESSAGE,
        ),
      )
      return
    }

    if (
      samplingAreasFeatures.some(
        (s, i) =>
          samplingAreasFeatures.findIndex(s2 => s2.properties.name === s.properties.name) !== i,
      )
    ) {
      setDisableActionReason(
        messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_SAMPLING_AREA_DUPLICATE_NAMES_NAME_MESSAGE,
        ),
      )
      return
    }

    const samplingAreaSizeShortedThanMinimumAllowedIndex = samplingAreasFeatures.findIndex(
      s => GeoJSON.hectareArea(s) < miniumFeatureHectareAllowed,
    )
    if (samplingAreaSizeShortedThanMinimumAllowedIndex !== -1) {
      setDisableActionReason(
        messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_SAMPLING_AREA_GEOMETRY_LOWER_THAN_MINIMUM_ALLOWED_MESSAGE,
          [
            samplingAreasFeatures[samplingAreaSizeShortedThanMinimumAllowedIndex].properties.name,
            miniumFeatureHectareAllowed,
          ],
        ),
      )
      return
    }

    setDisableActionReason(null)
  }, [samplingAreasFeatures, mapAction, setDisableActionReason])

  return (
    <>
      {(loading || !farm) && (
        <Stack
          sx={{
            height: drawActivated ? '75vh' : '85vh',
            overflowY: 'scroll',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Stack>
      )}

      {!loading && farm && (
        <Stack sx={{ height: drawActivated ? '75vh' : '85vh', overflowY: 'scroll' }}>
          {!drawActivated && wasSaved && (
            <ClassificationButtons
              canEditClasification={canEditClasification}
              clasificationAlreadyCreatedMessage="Ya se ha realizado la estratificación del establecimiento."
              clasificationType="Estratificación"
              deleteClasification={async () => {
                await processLoading({
                  loadingMessage: 'Borrando estratificación...',
                  errorMessage: 'Error al borrar la estratificación de exclusión.',
                  doAction: async ({ token }) => {
                    await ruutsApi.samplingAreas.deleteByFarm({ farmId: farm.id, token })
                    setSamplingAreas([])
                    setSamplingAreasFeatures([])
                  },
                })
              }}
              deleteMessageConfirmation="Esta a punto de eliminar todos los estratos creados hasta el momento y no se van a poder recuperar. ¿Desea continuar?"
              editMessageButton="Re-estratificar establecimiento"
              nextStepMessage="Continuar con el Plan de Monitoreo"
              nextStepTabIndex={2}
              setDrawActivated={setDrawActivated}
              setLoading={setLoading}
              setRestratification={setRestratification}
              setTabValue={setTabValue}
              setWasSaved={setWasSaved}
            >
              Borrar estratificación
            </ClassificationButtons>
          )}
          {!drawActivated && !wasSaved && (
            <ClassificationCreation
              georaster={georaster}
              perimeter={perimeter}
              previewSampling={previewSampling}
              setDrawActivated={setDrawActivated}
              setGeoraster={setGeoraster}
              setPreviewSampling={setPreviewSampling}
              setSamplingAreasFeatures={setSamplingAreasFeatures}
              setVectorized={setVectorized}
              supportedCreationModes={supportedCreationModes}
              type="Stratification"
            />
          )}
          {drawActivated && !wasSaved && (
            <>
              <BackButton
                reloadFeatures={() => loadSamplingAreas()}
                setDrawActivated={setDrawActivated}
                setMapAction={setMapAction}
                setWasSaved={setWasSaved}
              />
              <Grid container sx={{ padding: '0 10px' }}>
                <Grid item mt={2} sx={{ textAlign: 'center' }} xs={12}>
                  <Box sx={{ padding: '10px', textAlign: 'center' }}>
                    <Button
                      disabled={mapAction === 'edit'}
                      size="small"
                      startIcon={<AddIcon />}
                      variant="outlined"
                      onClick={() => {
                        setSelectedArea(null)
                        setSelectedAreaType('strata')
                        setMapAction('draw')
                      }}
                    >
                      Crear estrato
                    </Button>
                  </Box>
                </Grid>
                {samplingAreasFeatures &&
                  samplingAreasFeatures.map((strata, index) => {
                    return (
                      <Grid key={index} item xs={12}>
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{
                            alignItems: 'center',
                            marginTop: '10px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            sx={{
                              width: '20px',
                              minWidth: '20px',
                              height: '20px',
                              borderRadius: '50%',
                              backgroundColor: strata.properties.color,
                            }}
                            onClick={() => {
                              setSamplingAreasFeatures(prev =>
                                prev.map((samplingArea, indexPrev) => ({
                                  ...samplingArea,
                                  properties: {
                                    ...samplingArea.properties,
                                    selected: indexPrev === index,
                                  },
                                })),
                              )
                            }}
                          />
                          <TextField
                            error={
                              samplingAreasFeatures.filter(
                                s => s.properties.name === strata.properties.name,
                              ).length > 1 || !strata.properties.name
                            }
                            placeholder="Nombre"
                            value={strata.properties.name}
                            variant="standard"
                            onChange={e => {
                              setSamplingAreasFeatures(prev => {
                                const newStratas = [...prev]
                                newStratas[index].properties.name = e.target.value
                                return newStratas
                              })
                              setPartialChanges(true)
                            }}
                          />
                          <Typography variant="p">
                            {strata.properties?.area?.toFixed(2)} has
                          </Typography>
                          <Stack direction="row" spacing={0} sx={{ alignItems: 'center' }}>
                            <IconButton
                              color="primary"
                              disabled={mapAction === 'edit' && selectedArea !== strata}
                              size="small"
                              sx={{
                                display:
                                  mapAction === 'edit' && selectedArea === strata
                                    ? 'none'
                                    : 'initial',
                              }}
                              variant="contained"
                              onClick={() => {
                                setSelectedArea(strata)
                                setSelectedAreaType('strata')
                                setMapAction('draw')
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                            {mapAction === 'edit' && selectedArea === strata ? (
                              <>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    setSelectedArea(strata)
                                    setSelectedAreaType('strata')
                                    setMapAction('done')
                                  }}
                                >
                                  <DoneIcon />
                                </IconButton>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    setSelectedArea(strata)
                                    setSelectedAreaType('strata')
                                    setMapAction(null)
                                    setPartialChanges(false)
                                  }}
                                >
                                  <ClearOutlinedIcon />
                                </IconButton>
                              </>
                            ) : (
                              <IconButton
                                color="primary"
                                disabled={mapAction === 'edit' && selectedArea !== strata}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  setSelectedArea(strata)
                                  setSelectedAreaType('strata')
                                  setMapAction('edit')
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                            <IconButton
                              color="error"
                              disabled={mapAction === 'edit' && selectedArea !== strata}
                              size="small"
                              sx={{
                                display:
                                  mapAction === 'edit' && selectedArea === strata
                                    ? 'none'
                                    : 'initial',
                              }}
                              variant="contained"
                              onClick={() => {
                                setSamplingAreasFeatures(prev => {
                                  const newStratas = prev.filter(s => s !== strata)
                                  return newStratas
                                })
                                setMapAction('remove')
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Grid>
                    )
                  })}
              </Grid>
            </>
          )}
        </Stack>
      )}

      {drawActivated && samplingAreasFeatures && !wasSaved && !restratification && (
        <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'center' }}>
          <LoadingButton
            disableActionReason={disableActionReason}
            loading={loading}
            onClick={saveStratification}
          >
            Guardar Estratificación
          </LoadingButton>
        </Box>
      )}

      {drawActivated && samplingAreasFeatures && !wasSaved && restratification && (
        <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'center' }}>
          <LoadingButton
            disableActionReason={disableActionReason}
            loading={loading}
            onClick={updateStratification}
          >
            Actualizar Estratificación
          </LoadingButton>
        </Box>
      )}
    </>
  )
}

export default StratificationPanel
