/* eslint-disable no-param-reassign */
import { useCallback } from 'react'
import { v4 as UUIDv4 } from 'uuid'

const DRAFT = 'draft-'

export function useDraftFormStorage({ namespace, farmId, year }) {
  const getStorageKey = useCallback(
    () => `${namespace}-${farmId}-${year}`,
    [namespace, farmId, year],
  )

  const getDrafts = useCallback(() => {
    const key = getStorageKey()
    let savedDrafts = JSON.parse(window.localStorage.getItem(key)) || []
    if (!Array.isArray(savedDrafts)) {
      console.warn(`Data invalida encontrada en el storage: ${key}. Reseteando borradores.`)
      savedDrafts = []
      window.localStorage.setItem(key, JSON.stringify(savedDrafts))
    }
    return savedDrafts
  }, [getStorageKey])

  const addDraft = draft => {
    draft.id = `${DRAFT}${UUIDv4()}`
    draft.isDraft = true
    if (draft.selectedPaddocks) {
      draft.paddockIds = draft.selectedPaddocks
    }
    const drafts = getDrafts()
    const newDrafts = [...drafts, draft]
    window.localStorage.setItem(getStorageKey(), JSON.stringify(newDrafts))
  }

  const upsertDraft = newDraft => {
    const drafts = getDrafts()
    const index = drafts.findIndex(draft => draft.id === newDraft.id)
    if (index !== -1) {
      if (newDraft.selectedPaddocks) {
        newDraft.paddockIds = newDraft.selectedPaddocks
      }
      drafts[index] = { ...drafts[index], ...newDraft }
      window.localStorage.setItem(getStorageKey(), JSON.stringify(drafts))
    } else {
      addDraft(newDraft)
    }
  }

  const clearDrafts = () => {
    const key = getStorageKey()
    window.localStorage.removeItem(key)
  }

  const removeFromDrafts = metricId => {
    const drafts = getDrafts()

    if (Array.isArray(drafts)) {
      const newDrafts = drafts.filter(({ id }) => id !== metricId)
      if (newDrafts.length > 0) {
        window.localStorage.setItem(getStorageKey(), JSON.stringify(newDrafts))
      } else {
        clearDrafts()
      }
    }
  }

  return {
    getStorageKey,
    addDraft,
    upsertDraft,
    getDrafts,
    clearDrafts,
    removeFromDrafts,
  }
}
