import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Typography, Button, Container, Paper, Divider } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import AppContext from '../../context/appContext'
import { contactSupportReasons } from '../../components/GlobalLoadingModal/GlobalLoadingModal'
import { openSupportForm } from './errors'
import { PROGRAMS } from '../constants'

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate()
  const { user, currentFarm, setPartialChanges, setHideNavbar } = useContext(AppContext)
  const location = useLocation()

  const handleRedirect = () => {
    resetErrorBoundary()
    navigate('/')
  }

  const handleReportError = () => {
    const unexpectedError = { ...error, unexpected: true }
    const reason = `Farm ID: ${currentFarm?.id}\nRoute: ${location.pathname}\n`
    openSupportForm({
      user,
      farm: currentFarm,
      title: contactSupportReasons.Generic.title,
      reason,
      error: unexpectedError,
    })
  }

  const handleReload = () => {
    setPartialChanges(false)
    setHideNavbar(false)
    resetErrorBoundary()
    navigate(location.pathname)
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '90vh',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ErrorIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
          <Typography gutterBottom component="h1" variant="h4">
            Algo salió mal
          </Typography>
          <Typography component="h2" sx={{ fontWeight: 'bold' }} textAlign="center" variant="h6">
            Hubo un problema. Inténtelo de nuevo más tarde.
            <br />
            Si persiste, contáctenos.
          </Typography>
          <Divider sx={{ width: '100%', my: 2 }} />
          <Box
            sx={{
              width: '100%',
              mb: 2,
              backgroundColor: 'hsla(60, 4%, 84%, 0.3)',
              borderRadius: '5px',
              padding: '10px',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }} variant="body2">
              Detalles para soporte técnico
            </Typography>
            <Typography variant="body2">Tipo de error: {error.message}</Typography>
            <Typography variant="body2">Establecimiento: {currentFarm?.name || 'N/A'}</Typography>
            <Typography variant="body2">
              ID del Establecimiento: {currentFarm?.id || 'N/A'}
            </Typography>
            <Typography variant="body2">
              Programa: {PROGRAMS[currentFarm?.programId] || 'N/A'}
            </Typography>
            <Typography variant="body2">Ruta: {location.pathname}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              mt: 2,
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Button color="primary" variant="outlined" onClick={handleRedirect}>
              Ir al inicio
            </Button>
            <Button color="primary" variant="outlined" onClick={handleReportError}>
              Reportar Error
            </Button>
            <Button color="primary" variant="contained" onClick={handleReload}>
              Recargar
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}
