import { useContext, useEffect, useState } from 'react'
import AppContext from '../context/appContext'
import { userRoles } from '../utils/userRoles'

export const useViewOnly = () => {
  const { userRoleSelected } = useContext(AppContext)
  const [isViewOnly, setIsViewOnly] = useState(false)

  useEffect(() => {
    setIsViewOnly(userRoleSelected === userRoles.Analyst)
  }, [userRoleSelected])

  return { isViewOnly }
}
