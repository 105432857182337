/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-await */
import { useAuth0 } from '@auth0/auth0-react'
import { Delete, UploadFile } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CircleIcon from '@mui/icons-material/Circle'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import DoneIcon from '@mui/icons-material/Done'
import { ButtonGroup } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Fragment, useContext, useEffect, useState } from 'react'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import AppContext from '../../context/appContext'
import { updateFarmSubdivision } from '../../services/farmMapping/updateFarmSubdivision'
import { ruutsApi } from '../../services/ruutsApi'
import deleteApiData from '../../services/ruutsApi/deleteApiData.js'
import endpoints from '../../services/ruutsApi/endpoints'
import { errors } from '../../services/ruutsApi/errors.js'
import getApiData from '../../services/ruutsApi/getApiData'
import postApiData from '../../services/ruutsApi/postApiData'
import { GeoJSON } from '../../utils/GeoJSON/index'
import { processLoading } from '../../utils/Loading/processLoading'
import {
  checkIntersectionWithOtherFeatures,
  checkIntersectionWithPerimeter,
} from '../../utils/mapDrawHandle'
import { messages } from '../../utils/messages/index.js'
import CustomAlert from '../Alert/CustomAlert'
import { EditionButton } from '../Button/Button'
import { DeleteIconButton } from '../Button/DeleteIconButton'
import { EditIconButton } from '../Button/EditIconButton'
import { LoadingButton } from '../Button/LoadingButton'
import { ModalTypes } from '../GlobalConfirmationModal/GlobalConfirmationModal'
import { displayErrorMessageModes } from '../GlobalLoadingModal/GlobalLoadingModal.jsx'
import { mapActions } from './AllotmentMap'
import BaseFarmSubdivisionYearSelect from './BaseFarmSubdivisionYearSelect'
import YearSelector from './YearSelector'
import { useViewOnly } from '../../hooks/useViewOnly.js'

const AllotmentPanel = ({
  farmSubdivisions,
  yearsAllowed,
  setFarmSubdivisions,
  paddocks,
  setPaddocks,
  paddocksFeatures,
  setPaddocksFeatures,
  farm,
  wasSaved,
  setWasSaved,
  drawActivated,
  setDrawActivated,
  setMapAction,
  mapAction,
  setSelectedArea,
  selectedArea,
  setSelectedAreaType,
  formDefinitions,
}) => {
  const [reallotment, setReallotment] = useState(false)
  const [savedYears, setSavedYears] = useState([])
  const [selectedYears, setSelectedYears] = useState([])
  const [selectedFarmSubdivision, setSelectedFarmSubdivision] = useState()
  const [baseFarmSubdivisionYear, setBaseFarmSubdivisionYear] = useState('')
  const { getAccessTokenSilently, user } = useAuth0()
  const [disableActionReason, setDisableActionReason] = useState(null)
  const {
    userRoleSelected,
    partialChanges,
    setPartialChanges,
    loadingModalConfig,
    setLoadingModalConfig,
    setConfirmationModalConfig,
  } = useContext(AppContext)

  const { isViewOnly } = useViewOnly()

  const handleIntersections = pF => {
    if (!farm) return

    setPaddocksFeatures(() => {
      let newFeatures = []
      pF.forEach(paddock => {
        const p = checkIntersectionWithPerimeter(paddock, farm.toGeoJSON)
        newFeatures = checkIntersectionWithOtherFeatures(p, newFeatures)
        p.properties = paddock.properties
        p.properties.area = GeoJSON.hectareArea(p)
        p.metrics = paddock.metrics
        newFeatures = [...newFeatures, p]
      })
      return newFeatures
    })
  }

  const resetControls = () => {
    setPaddocksFeatures([])
    setSelectedYears([])
    setSelectedFarmSubdivision({})
    setDrawActivated(false)
    setWasSaved(true)
    setMapAction(mapActions.Reset)
    setPartialChanges(false)
  }

  const reloadInformation = async token => {
    const newFarmSubdivisions = await getApiData(
      endpoints.farmSubdivisions,
      { farmId: farm.id },
      token,
    )
    const newPaddocks = await ruutsApi.paddocks.getPaddocks({ farmId: farm.id, token })
    setFarmSubdivisions(newFarmSubdivisions)
    setPaddocks(newPaddocks)

    resetControls()
  }

  const saveAllotment = async () => {
    await processLoading({
      getToken: getAccessTokenSilently,
      setLoadingModalConfig,
      loadingMessage: 'Generando lote...',
      doAction: async ({ token }) => {
        const paddocksToCreate = paddocksFeatures.map(paddock => {
          return {
            name: paddock.properties.name,
            geometry: paddock.geometry,
            farmId: farm.id,
            createdBy: user.email,
            updatedBy: user.email,
          }
        })

        const farmSubdivisionsToCreateBySelectedYears = await ruutsApi.paddocks.createPaddocks({
          farm,
          years: selectedYears,
          paddocks: paddocksToCreate,
          token,
        })
        await Promise.all(
          farmSubdivisionsToCreateBySelectedYears.map(
            async farmSubdivision =>
              await postApiData(endpoints.farmSubdivisions, farmSubdivision, token),
          ),
        )

        await reloadInformation(token)
      },
    })
  }

  const updateAllotment = async () => {
    await processLoading({
      getToken: getAccessTokenSilently,
      setLoadingModalConfig,
      loadingMessage: 'Guardando lote...',
      doAction: async ({ token }) => {
        const paddockIdsToDelete = selectedFarmSubdivision.paddockIds.filter(paddockId => {
          return !paddocksFeatures.find(
            paddockFeature => paddockFeature.properties.id === paddockId,
          )
        })
        const paddocksFeaturesToUpdate = paddocksFeatures.filter(paddock => paddock.properties.id)
        const paddocksFeaturesToCreate = paddocksFeatures.filter(paddock => !paddock.properties.id)

        await Promise.all(
          paddockIdsToDelete.map(
            async paddockId =>
              await ruutsApi.paddocks.deletePaddocks({
                id: paddockId,
                userRole: userRoleSelected,
                token,
              }),
          ),
        )

        await ruutsApi.paddocks.updatePaddocksFeatures({
          paddocks: paddocksFeaturesToUpdate,
          farmId: farm.id,
          token,
        })

        const paddocksToCreate = paddocksFeaturesToCreate.map(paddock => {
          return {
            name: paddock.properties.name,
            geometry: paddock.geometry,
            farmId: farm.id,
          }
        })
        const paddocksCreated = await ruutsApi.paddocks.createPaddocks({
          farm,
          years: [selectedFarmSubdivision],
          paddocks: paddocksToCreate,
          token,
        })

        const paddockIdsCreated = paddocksCreated.flatMap(paddock => paddock.paddockIds)
        const paddockIdsToUpdated = paddocksFeaturesToUpdate.map(paddock => paddock.properties.id)
        const newSelectedFarmSubdivision = { ...selectedFarmSubdivision }
        newSelectedFarmSubdivision.paddockIds = paddockIdsCreated.concat(paddockIdsToUpdated)
        await updateFarmSubdivision(token, newSelectedFarmSubdivision)

        await reloadInformation(token)
      },
    })
  }

  useEffect(() => {
    if (farmSubdivisions.length) {
      const years = farmSubdivisions.map(subdivision => subdivision.year)
      setSavedYears(years)
    }
  }, [farmSubdivisions])

  useEffect(() => {
    const hasSelectedYears = selectedYears.length > 0
    setPartialChanges(hasSelectedYears && drawActivated)
    if (selectedYears.length === 1) {
      if (!drawActivated) {
        const newSelectedFarmSubdivision = farmSubdivisions.find(
          subdivision => subdivision.year === selectedYears[0],
        )
        setSelectedFarmSubdivision(newSelectedFarmSubdivision)
        const paddocksFromSubdivision = newSelectedFarmSubdivision.paddockIds

        const selectedPaddocks = paddocks.filter(paddock =>
          paddocksFromSubdivision.includes(paddock.id),
        )

        selectedPaddocks.forEach(p => {
          p.toGeoJSON.properties.area = GeoJSON.hectareArea(p.toGeoJSON)
        })
        handleIntersections(
          selectedPaddocks.map(paddock => ({ ...paddock.toGeoJSON, metrics: paddock.metrics })),
        )
      }
    }
  }, [selectedYears])

  useEffect(() => {
    if (baseFarmSubdivisionYear) {
      const selectedFarmSubdivision = farmSubdivisions.find(
        subdivision => subdivision.year === baseFarmSubdivisionYear,
      )
      const paddocksFromSubdivision = selectedFarmSubdivision.paddockIds

      const selectedPaddocks = paddocks.filter(paddock =>
        paddocksFromSubdivision.includes(paddock.id),
      )

      selectedPaddocks.forEach(p => {
        p.toGeoJSON.properties.area = GeoJSON.hectareArea(p.toGeoJSON)
      })

      handleIntersections(selectedPaddocks.map(paddock => paddock.toGeoJSON))
      setPartialChanges(true)
    } else {
      setPaddocksFeatures([])
    }
  }, [baseFarmSubdivisionYear])

  const handleBack = () => {
    if (!partialChanges) {
      resetControls()
      return
    }
    setConfirmationModalConfig({
      open: true,
      title: 'Cambios sin guardar',
      message: 'Si confirma volver, se perderán los cambios. ¿Desea continuar?',
      confirmLabel: 'Continuar',
      cancelLabel: 'Cancelar',
      confirmAction: () => {
        resetControls()
      },
    })
  }

  useEffect(() => {
    if (paddocksFeatures.length === 0) {
      setDisableActionReason(
        messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_FARM_SUBDIVISION_EMPTY_GEOMETRIES_MESSAGE,
        ),
      )
      return
    }

    if (paddocksFeatures.some(s => s.properties.name === '')) {
      setDisableActionReason(
        messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_FARM_SUBDIVISION_EMPTY_GEOMETRY_NAME_MESSAGE,
        ),
      )
      return
    }

    if (
      paddocksFeatures.some(
        (s, i) => paddocksFeatures.findIndex(s2 => s2.properties.name === s.properties.name) !== i,
      )
    ) {
      setDisableActionReason(
        messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_FARM_SUBDIVISION_DUPLICATE_NAMES_NAME_MESSAGE,
        ),
      )
      return
    }

    if (selectedYears.length === 0) {
      setDisableActionReason(
        messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_FARM_SUBDIVISION_YEAR_NON_SELECTED_MESSAGE,
        ),
      )
      return
    }

    if (mapAction === mapActions.Edit) {
      setDisableActionReason(
        messages.getMessage(messages.keys.ERROR_CANNOT_EDIT_GEOMETRY_APPLY_CHANGES_MESSAGE),
      )
      return
    }

    if (isViewOnly) {
      setDisableActionReason(messages.getMessage(messages.keys.NO_PERMISSIONS_GRANTED))
      return
    }

    setDisableActionReason(null)
  }, [paddocksFeatures, mapAction, selectedYears, setDisableActionReason, isViewOnly])

  useEffect(() => {
    if (mapAction) {
      setPartialChanges(
        [
          mapActions.Drawn,
          mapActions.Edited,
          mapActions.ConfirmedModification,
          mapActions.Removed,
        ].includes(mapAction),
      )
    }
  }, [mapAction])

  async function getPaddockMetrics(paddockId) {
    try {
      const token = await getAccessTokenSilently()
      return await getApiData(endpoints.metricsByPaddockId, { id: paddockId }, token)
    } catch (error) {
      throw new errors.RuutsError(`Unable to get metrics for paddock id: ${paddockId}`)
    }
  }

  async function handleDeletePaddock({ paddock }) {
    const paddockMetrics = paddock?.properties?.id
      ? await getPaddockMetrics(paddock.properties.id)
      : []

    const getDataCollectionCategories = ({ metrics }) => {
      const namespaces = metrics?.map(
        metric =>
          formDefinitions.dataCollection.find(
            formDefinition => formDefinition.namespace === metric.namespace,
          )?.label,
      )
      return namespaces.reduce((acc, cur) => {
        if (!acc.includes(cur)) {
          acc = [...acc, cur]
        }
        return acc
      }, [])
    }

    const deleteMessageConfirmation = ({ paddockMetrics }) => {
      if (!paddockMetrics?.length) return null

      const dcNamespaces = getDataCollectionCategories({ metrics: paddockMetrics })
      return (
        <>
          <Box sx={{}}>
            <Typography
              sx={{
                fontSize: 16,
              }}
            >
              <strong>Advertencia</strong>: El lote posee datos anualizados asociados.
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
              }}
            >
              Se borrará el lote de cada dato asociado en las pestañas:
            </Typography>
          </Box>
          <List dense>
            {dcNamespaces.map((namespace, index) => (
              <ListItem key={index}>
                <ListItemIcon style={{ minWidth: '25px' }}>
                  <Avatar sx={{ width: 14, height: 14, marginRight: 0 }}>
                    <CircleIcon style={{ color: 'black' }} /> {/* Render the check icon */}
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={namespace} />
              </ListItem>
            ))}
          </List>
        </>
      )
    }

    setConfirmationModalConfig({
      open: true,
      modalType: ModalTypes.DeleteModalType,
      title: `Eliminar lote ${paddock.properties.name}`,
      message: '¿Está seguro de eliminar el lote? ',
      confirmAction: () => {
        setPaddocksFeatures(prev => {
          const newPaddocks = prev.filter(s => s !== paddock)
          return newPaddocks
        })
        setMapAction(mapActions.Removed)
      },
      children: deleteMessageConfirmation({ paddockMetrics }),
    })
  }

  const handleDeleteFarmSubdivision = async () => {
    setConfirmationModalConfig({
      modalType: ModalTypes.DeleteModalType,
      open: true,
      title: `Eliminar loteo ${selectedFarmSubdivision.year}`,
      message: `¿Está seguro de eliminar el loteo completo del año ${selectedFarmSubdivision.year}?`,
      confirmAction: async () =>
        processLoading({
          setLoadingModalConfig,
          getToken: getAccessTokenSilently,
          loadingTitle: `Eliminando Loteo Año ${selectedFarmSubdivision.year}`,
          errorMessage: 'Error al eliminar el loteo.',
          displayErrorMessageMode: displayErrorMessageModes.dialog,
          doAction: async ({ token }) => {
            await deleteApiData(
              `${endpoints.farmSubdivisions}/${selectedFarmSubdivision.id}?deletePaddocks=true`,
              {},
              token,
            )
            await reloadInformation(token)
          },
        }),
    })
  }

  const handleTemplateUpload = async e => {
    const [file] = e.target.files
    const formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('farmId', farm.id)
    processLoading({
      getToken: getAccessTokenSilently,
      setLoadingModalConfig,
      loadingTitle: 'Cargando archivo',
      doAction: async ({ token }) => {
        await postApiData(endpoints.farmSubdivisionsTemplate, formData, token)
        await reloadInformation(token)
      },
    })
  }

  return (
    <>
      {(loadingModalConfig.open || !farm) && (
        <Stack
          sx={{
            height: drawActivated ? '75vh' : '85vh',
            overflowY: 'scroll',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Stack>
      )}

      {!loadingModalConfig.open && farm && (
        <Stack sx={{ height: drawActivated ? '75vh' : '85vh', overflowY: 'scroll' }}>
          {!drawActivated && (
            <>
              <Stack alignItems="center" direction="row" justifyContent="space-between">
                {wasSaved && (
                  <CustomAlert
                    message="Los cambios se guardaron correctamente."
                    severity="success"
                    title="Éxito"
                  />
                )}
                {!wasSaved && (
                  <CustomAlert
                    message="Un Loteo es la disposición de los Lotes en un momento determinado."
                    severity="info"
                    title="¿Sabías qué?"
                  />
                )}
              </Stack>
              <Stack sx={{ marginTop: 2, padding: '20px', textAlign: 'center' }}>
                <Typography variant="h6">Loteo</Typography>

                <Typography variant="p">
                  Cree un Loteo y defina al período que corresponde.
                </Typography>

                <Stack spacing={2} sx={{ marginTop: 2, width: '100%' }}>
                  {yearsAllowed.includes('TEMPLATE') && (
                    <Button
                      color="primary"
                      component="label"
                      disabled={isViewOnly}
                      endIcon={<UploadFile />}
                      sx={{ width: '100%' }}
                      variant="outlined"
                    >
                      CARGAR LOTEO BASE (KML)
                      <input
                        hidden
                        accept=".kml"
                        id="kml-upload-button"
                        type="file"
                        onChange={handleTemplateUpload}
                      />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    disabled={isViewOnly}
                    endIcon={<AddIcon />}
                    sx={{ width: '100%' }}
                    variant="contained"
                    onClick={() => {
                      setSelectedYears([])
                      setSelectedFarmSubdivision({})
                      setPaddocksFeatures([])
                      setDrawActivated(true)
                      setReallotment(false)
                    }}
                  >
                    Crear nuevo loteo
                  </Button>
                </Stack>
              </Stack>
            </>
          )}
          {!drawActivated && farmSubdivisions.length > 0 && (
            <Box sx={{ padding: '20px' }}>
              <Divider />
              <YearSelector
                multiselectYears={false}
                savedYears={savedYears}
                selectedYears={selectedYears}
                setSelectedYears={setSelectedYears}
                title="Loteos cargados"
                years={yearsAllowed}
              />
            </Box>
          )}
          {!drawActivated &&
            paddocksFeatures.length > 0 &&
            farmSubdivisions.length > 0 &&
            selectedYears.length > 0 && (
              <>
                <Typography sx={{ textAlign: 'center' }} variant="h6">
                  Loteo {selectedYears[0]}
                </Typography>
                <Box sx={{ padding: '20px 50px' }}>
                  {paddocksFeatures.map((paddock, index) => (
                    <Fragment key={index}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="p">{paddock.properties.name}</Typography>
                        <Typography variant="p">
                          {paddock.properties.area.toFixed(2)} has
                        </Typography>
                      </Stack>
                      <Divider />
                    </Fragment>
                  ))}
                </Box>
                <ButtonGroup fullWidth sx={{ padding: '20px', width: '100%' }}>
                  <EditionButton
                    color="error"
                    disabled={!selectedFarmSubdivision.allowEdition.value || isViewOnly}
                    endIcon={<Delete />}
                    showTooltip={!selectedFarmSubdivision.allowEdition.value}
                    tooltipTitle={selectedFarmSubdivision?.allowEdition?.reason}
                    onClick={handleDeleteFarmSubdivision}
                  >
                    Eliminar Loteo
                  </EditionButton>
                  <EditionButton
                    disabled={!selectedFarmSubdivision.allowEdition.value || isViewOnly}
                    showTooltip={!selectedFarmSubdivision.allowEdition.value}
                    tooltipTitle={selectedFarmSubdivision?.allowEdition?.reason}
                    onClick={() => {
                      setPartialChanges(false)
                      setDrawActivated(true)
                      setReallotment(true)
                    }}
                  >
                    Editar Loteo
                  </EditionButton>
                </ButtonGroup>
              </>
            )}
          {drawActivated && (
            <>
              <Box sx={{ padding: '10px' }}>
                <Stack
                  direction="row"
                  sx={{ alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {
                    handleBack()
                  }}
                >
                  <IconButton>
                    <ArrowBackIosIcon />
                  </IconButton>
                  <Typography sx={{ color: 'rgba(0, 0, 0, 0.54)' }} variant="p">
                    <b>Volver</b>
                  </Typography>
                  <Typography sx={{ marginLeft: 'auto' }} variant="h6">
                    <b>
                      {reallotment ? `Edición de Loteo ${selectedYears[0]}` : 'Creación de Loteo'}
                    </b>
                  </Typography>
                </Stack>
              </Box>
              <Grid container sx={{ padding: '0 10px' }}>
                <Grid item sx={{ textAlign: 'center' }} xs={12} />
                {!reallotment && (
                  <>
                    <Grid item sx={{ textAlign: 'center' }} xs={12}>
                      <YearSelector
                        multiselectYears
                        savedYears={savedYears}
                        selectedYears={selectedYears}
                        setSelectedYears={setSelectedYears}
                        title="Seleccione el período del loteo a crear"
                        years={yearsAllowed.filter(year => year !== 'TEMPLATE')}
                      />
                    </Grid>
                    <Grid item sx={{ textAlign: 'center' }} xs={12}>
                      <BaseFarmSubdivisionYearSelect
                        baseFarmSubdivisionYear={baseFarmSubdivisionYear}
                        savedYears={savedYears}
                        selectedYears={selectedYears}
                        setBaseFarmSubdivisionYear={setBaseFarmSubdivisionYear}
                        years={yearsAllowed}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Divider />
                  <Box sx={{ padding: '10px', textAlign: 'center' }}>
                    <Button
                      disabled={
                        mapAction === mapActions.Edit || selectedYears.length === 0 || isViewOnly
                      }
                      size="small"
                      startIcon={<AddIcon />}
                      variant="outlined"
                      onClick={() => {
                        setSelectedArea(null)
                        setSelectedAreaType('paddock')
                        setMapAction(mapActions.Draw)
                      }}
                    >
                      Crear Lote
                    </Button>
                  </Box>
                </Grid>
                {paddocksFeatures.length > 0 &&
                  paddocksFeatures.map((paddock, index) => {
                    return (
                      <Grid key={index} item xs={12}>
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{
                            alignItems: 'center',
                            marginTop: '10px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <TextField
                            error={
                              paddocksFeatures.filter(
                                s => s.properties.name === paddock.properties.name,
                              ).length > 1 || !paddock.properties.name
                            }
                            placeholder="Nombre"
                            value={paddock.properties.name}
                            variant="standard"
                            onChange={e => {
                              setPaddocksFeatures(prev => {
                                const newPaddocks = [...prev]
                                newPaddocks[index].properties.name = e.target.value
                                return newPaddocks
                              })
                              setPartialChanges(true)
                            }}
                          />
                          <Typography variant="p">
                            {paddock.properties?.area?.toFixed(2)} has
                          </Typography>
                          <Stack direction="row" spacing={0} sx={{ alignItems: 'center' }}>
                            {mapAction === mapActions.Edit && selectedArea === paddock ? (
                              <>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    setSelectedArea(paddock)
                                    setSelectedAreaType('paddock')
                                    setMapAction(mapActions.ConfirmedModification)
                                  }}
                                >
                                  <DoneIcon />
                                </IconButton>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    setSelectedArea(paddock)
                                    setSelectedAreaType('paddock')
                                    setMapAction(mapActions.Cancelled)
                                  }}
                                >
                                  <ClearOutlinedIcon />
                                </IconButton>
                              </>
                            ) : (
                              <EditIconButton
                                key={`edit-${paddock.properties.id}`}
                                disabled={
                                  (mapAction === mapActions.Edit && selectedArea !== paddock) ||
                                  isViewOnly
                                }
                                onClick={() => {
                                  setSelectedArea(paddock)
                                  setSelectedAreaType('paddock')
                                  setMapAction(mapActions.Edit)
                                }}
                              />
                            )}
                            <DeleteIconButton
                              key={`delete-${paddock.properties.id}`}
                              disabled={isViewOnly}
                              sx={{
                                display:
                                  mapAction === mapActions.Edit && selectedArea === paddock
                                    ? 'none'
                                    : 'initial',
                              }}
                              onClick={() => {
                                handleDeletePaddock({ paddock })
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                    )
                  })}
              </Grid>
            </>
          )}
        </Stack>
      )}
      {drawActivated && paddocksFeatures && !reallotment && (
        <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'center' }}>
          <LoadingButton
            disableActionReason={disableActionReason}
            loading={loadingModalConfig.open}
            onClick={saveAllotment}
          >
            Guardar Loteo
          </LoadingButton>
        </Box>
      )}
      {drawActivated && paddocksFeatures && reallotment && (
        <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'center' }}>
          <LoadingButton
            disableActionReason={disableActionReason}
            loading={loadingModalConfig.open}
            onClick={updateAllotment}
          >
            Actualizar Loteo
          </LoadingButton>
        </Box>
      )}
    </>
  )
}

export default AllotmentPanel
