import endpoints from '../../ruutsApi/endpoints'
import updateApiData from '../../ruutsApi/updateApiData'
import { postApiDataWithPayloadResponse } from '../../ruutsApi/postApiData'

function featuresToEntities({ farmId, exclusionTypes, features }) {
  return features.map(exclusion => ({
    id: exclusion.properties?.id,
    name: exclusionTypes.find(
      exclusionType => exclusionType.id === exclusion.properties.exclusionAreaTypeId,
    ).es_AR,
    otherName: exclusion.properties.otherName,
    exclusionAreaTypeId: exclusion.properties.exclusionAreaTypeId,
    hasGrazingManagement: exclusion.properties.hasGrazingManagement,
    geometry: exclusion.geometry,
    farmId,
  }))
}

export const createExclusionAreasFeatureByFarm = async ({
  farmId,
  features,
  exclusionTypes,
  token,
}) => {
  if (!features?.length) return []

  const featuresToCreate = featuresToEntities({ farmId, features, exclusionTypes })
  return postApiDataWithPayloadResponse(endpoints.exclusionAreas, featuresToCreate, token)
}

export const updateExclusionAreasFeatureByFarm = async ({
  farmId,
  features,
  exclusionTypes,
  token,
}) => {
  if (!features?.length) return []

  const featuresToUpdate = featuresToEntities({ farmId, features, exclusionTypes })
  return updateApiData(endpoints.exclusionAreas, featuresToUpdate, token)
}
