import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import * as turf from '@turf/turf'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { FeatureGroup, LayersControl, MapContainer } from 'react-leaflet'
import { GeomanControls } from 'react-leaflet-geoman-v2'
import { GeoJSON } from '../../utils/GeoJSON/index'
import { customSnackbarError } from '../../utils/Snackbar/Snackbar'
import {
  checkIntersectionWithOtherFeatures,
  checkIntersectionWithPerimeter,
} from '../../utils/mapDrawHandle'
import BaseLayers from '../map/BaseLayers'

export const mapActions = {
  Draw: 0,
  Drawn: 1,
  Edit: 2,
  Edited: 3,
  Removed: 4,
  ConfirmedModification: 5,
  Cancelled: 6,
}

const styles = {
  paddockTooltip: {
    padding: '2px 6px',
    backgroundColor: '#ffffff66',
    border: '1px solid #ffffff00',
    borderRadius: '6px',
    color: '#ffffff',
  },
  exclusionTooltip: {
    padding: '2px 6px',
    backgroundColor: '#ffffff66',
    border: '1px solid #ffffff00',
    borderRadius: '6px',
    color: '#ffffff',
  },
}

const makeStyles = style => {
  return Object.entries(style).reduce((acc, [key]) => {
    acc[key] = key
    return acc
  }, {})
}

const AllotmentMap = ({
  farm,
  perimeter,
  paddocksFeatures,
  setPaddocksFeatures,
  selectedArea,
  selectedAreaType,
  mapWidth,
  mapHeight,
  mapAction,
  setMapAction,
}) => {
  const [map, setMap] = useState()
  const [mapLoading, setMapLoading] = useState(false)
  const center = [-35.329970415341656, -65.12262446073629]
  const [mapUpdated, setMapUpdated] = useState(0)
  const featureGroupRef = useRef()
  const classes = makeStyles(styles)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [newFeature, setNewFeature] = useState(null)

  const onGeomanControlMounted = () => {
    if (map) {
      map.pm.toggleControls()
    }
  }

  // init map, set perimeter & bounds
  useEffect(() => {
    if (map && perimeter) {
      L.geoJSON(perimeter, {
        style: {
          color: 'black',
          weight: 3,
          opacity: 1,
          fillOpacity: 0,
        },
        pmIgnore: true,
      }).addTo(map)
      map.fitBounds(L.geoJSON(perimeter).getBounds())
    }
  }, [perimeter])

  useEffect(() => {
    if (farm) {
      setMapLoading(true)
      setMapUpdated(prev => prev + 1)
    }
  }, [paddocksFeatures])

  // on map update
  useEffect(() => {
    setMapLoading(true)
    if (mapUpdated > 0 && map) {
      // remove all layers from map except base layers
      map.eachLayer(layer => {
        if (!layer._url) {
          map.removeLayer(layer)
        }
      })

      // add perimeter
      L.geoJSON(perimeter, {
        style: {
          color: 'black',
          weight: 3,
          opacity: 1,
          fillOpacity: 0,
        },
      }).addTo(map)

      // add paddocksFeatures
      paddocksFeatures.forEach(paddock => {
        if (paddock.geometry.type === 'MultiPolygon') {
          paddock.geometry.coordinates.forEach(polygon => {
            const feature = turf.polygon(polygon)
            feature.properties = paddock.properties
            L.geoJSON(feature, {
              style: {
                color: 'purple',
                weight: 2,
                opacity: 1,
                fillOpacity: 0.4,
              },
            })
              .bindTooltip(paddock.properties.name, {
                permanent: false,
                direction: 'center',
                className: classes.paddockTooltip,
                offset: [0, 0],
              })
              .addTo(map)
          })
        } else {
          L.geoJSON(paddock, {
            style: {
              color: 'purple',
              weight: 2,
              opacity: 1,
              fillOpacity: 0.4,
            },
          })
            .bindTooltip(paddock.properties.name, {
              permanent: false,
              direction: 'center',
              className: classes.paddockTooltip,
              offset: [0, 0],
            })
            .addTo(map)
        }
      })
    }
    setMapLoading(false)
  }, [mapUpdated])

  const getNewPaddockName = prev => {
    // find max number in paddock names
    let max = 0
    prev.forEach(paddock => {
      const num = parseInt(paddock.properties.name.split(' ')[1], 10)
      if (num > max) {
        max = num
      }
    })
    return `Lote ${max + 1}`
  }

  // On new feature added
  useEffect(() => {
    if (!farm || !newFeature) return

    try {
      let newPaddocksFeatures = [...paddocksFeatures]
      const newArea = checkIntersectionWithPerimeter(newFeature, farm.toGeoJSON, true)

      // if (map.exclusionAreasFeatures) {
      //   newArea = checkIntersectionWithExclusionAreas(newArea, map.exclusionAreasFeatures, true);
      //   if (!newArea) {
      //     return;
      //   }
      // }
      const newPaddockName = getNewPaddockName(newPaddocksFeatures)
      newPaddocksFeatures = checkIntersectionWithOtherFeatures(
        newArea,
        newPaddocksFeatures,
        true,
        'Creación no guardada. Al crear el lote nuevo, el lote {destinyPaddockName} resulta en un área inferior al mínimo permitido de {miniumFeatureHectareAllowed} has.',
      )

      newArea.properties = {
        area: GeoJSON.hectareArea(newArea),
        color: 'purple',
        name: newPaddockName,
      }

      setPaddocksFeatures([...newPaddocksFeatures, newArea])
      setMapAction(mapActions.Drawn)
    } catch (error) {
      customSnackbarError(error.message, error, enqueueSnackbar, closeSnackbar)
      setMapAction(mapActions.Cancelled)
    } finally {
      setMapUpdated(prev => prev + 1)
    }
  }, [newFeature])

  const handleCreate = async layer => {
    setNewFeature(layer.toGeoJSON())
  }

  const handleEdit = async (layers, selectedAreaEdit) => {
    if (!farm || !paddocksFeatures?.length) return

    try {
      setMapLoading(true)
      let newPaddocksFeatures = [...paddocksFeatures]

      if (layers.length === 1) {
        let edited = layers[0].toGeoJSON()

        if (map.selectedAreaType === 'paddock') {
          const i = newPaddocksFeatures.indexOf(map.selectedArea)
          newPaddocksFeatures = newPaddocksFeatures.filter(paddock => paddock !== map.selectedArea)
          edited = checkIntersectionWithPerimeter(edited, farm.toGeoJSON, true)
          // if (map.exclusionAreasFeatures) {
          //   edited = checkIntersectionWithExclusionAreas(edited, map.exclusionAreasFeatures, true);
          // }
          newPaddocksFeatures = checkIntersectionWithOtherFeatures(
            edited,
            newPaddocksFeatures,
            true,
          )

          edited.properties = selectedAreaEdit.properties
          edited.properties.area = GeoJSON.hectareArea(edited)
          newPaddocksFeatures.splice(i, 0, edited)
        }
      } else {
        const coordinates = []

        layers.forEach(layer => {
          coordinates.push(layer.toGeoJSON().geometry.coordinates)
        })

        let edited = turf.multiPolygon(coordinates)

        if (map.selectedAreaType === 'paddock') {
          const i = newPaddocksFeatures.indexOf(map.selectedArea)
          newPaddocksFeatures = newPaddocksFeatures.filter(paddock => paddock !== map.selectedArea)
          edited = checkIntersectionWithPerimeter(edited, farm.toGeoJSON, true)
          newPaddocksFeatures = checkIntersectionWithOtherFeatures(
            edited,
            newPaddocksFeatures,
            true,
          )

          edited.properties = selectedAreaEdit.properties
          edited.properties.area = GeoJSON.hectareArea(edited)
          edited.properties.color = 'purple'

          newPaddocksFeatures.splice(i, 0, edited)
        }
      }

      setPaddocksFeatures(newPaddocksFeatures)
      setMapAction(mapActions.Edited)
    } catch (error) {
      customSnackbarError(error.message, error, enqueueSnackbar, closeSnackbar)
      setMapAction(mapActions.Cancelled)
    } finally {
      setMapUpdated(prev => prev + 1)
    }
  }

  const findLayersBySelectedArea = area => {
    if (!area) {
      return null
    }

    const polygons = []
    if (area.geometry.type === 'Polygon') {
      polygons.push(area)
    }
    if (area.geometry.type === 'MultiPolygon') {
      area.geometry.coordinates.forEach(poly => {
        const geoJSON = turf.polygon(poly)
        geoJSON.properties = area.properties
        polygons.push(geoJSON)
      })
    }

    const layers = []
    map.eachLayer(l => {
      if (l._url) return
      if (l instanceof L.Polygon) {
        polygons.forEach(polygon => {
          if (l.toGeoJSON().properties.name === polygon.properties.name) {
            layers.push(l)
          }
        })
      }
    })
    return layers
  }

  useEffect(() => {
    if (map) {
      map.selectedArea = selectedArea
      map.selectedAreaType = selectedAreaType
      const selectedLayers = findLayersBySelectedArea(selectedArea)
      switch (mapAction) {
        case mapActions.Draw:
          map.pm.enableDraw('Polygon', {})
          break
        case mapActions.Edit:
          selectedLayers?.forEach(layer => {
            layer.pm.enable({
              allowSelfIntersection: false,
              snappable: true,
            })
          })
          break
        case mapActions.Removed:
          setMapUpdated(prev => prev + 1)
          break
        case mapActions.ConfirmedModification:
          selectedLayers.forEach(layer => {
            layer.pm.disable()
          })
          handleEdit(selectedLayers, selectedArea)
          break
        case mapActions.Drawn:
        case mapActions.Edited:
        case mapActions.Cancelled:
          map.pm.disableDraw('Polygon')
          selectedLayers?.forEach(layer => {
            layer.pm.disable()
          })
          setMapUpdated(prev => prev + 1)
          break
        default:
          break
      }
    }
  }, [mapAction])

  return (
    <Container disableGutters sx={{ width: '100%' }}>
      {mapLoading && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255,255,255,0.4)',
            zIndex: 9999,
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <MapContainer
        ref={setMap}
        scrollWheelZoom
        zoomControl
        attributionControl={false}
        center={center}
        style={{ height: mapHeight - 100, width: mapWidth }}
        wheelPxPerZoomLevel={60}
        zoom={5}
        zoomDelta={1}
        zoomSnap={0.1}
      >
        <LayersControl>
          <BaseLayers />
        </LayersControl>

        {map && (
          <FeatureGroup ref={featureGroupRef}>
            <GeomanControls
              globalOptions={{
                continueDrawing: false,
                snapDistance: 5,
                allowSelfIntersection: false,
                templineStyle: {
                  color: selectedArea?.properties.color || 'grey',
                },
                hintlineStyle: {
                  color: selectedArea?.properties.color || 'grey',
                  dashArray: [5, 5],
                  weight: 2,
                },
                layersToCut: featureGroupRef.current,
                layerGroup: featureGroupRef.current,
              }}
              lang="es"
              options={{
                position: 'topleft',
                drawCircle: false,
                drawMarker: false,
                drawRectangle: false,
                drawPolyline: false,
                drawCircleMarker: false,
                drawText: false,
                rotateMode: false,
                dragMode: false,
                editMode: true,
                cutPolygon: true,
                drawPolygon: true,
                removalMode: true,
              }}
              onCreate={e => handleCreate(e.layer)}
              onMount={e => onGeomanControlMounted(e)}
            />
          </FeatureGroup>
        )}
      </MapContainer>
    </Container>
  )
}

export default AllotmentMap
