/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
import { useAuth0 } from '@auth0/auth0-react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AppContext from '../../context/appContext'
import lfStore from '../../lfstore/lfStore'
import { dataCollectionStatementService } from '../../services/farmMapping/datacollectionStatement'
import getDataCollectionFormDefinitions from '../../services/ruutsApi/formDefinitions/getFormDefinitions'
import getProgram from '../../services/ruutsApi/getProgram'
import { formModes, metricEventStatus } from '../../utils/constants'
import { namespacesValidations } from '../../utils/dataCollection/namespacesValidations/index.js'
import { roleTraduction } from '../../utils/roleTraduction'
import FormContainer from '../FormContainer/FormContainer'
import DirtyForm from '../formPanel/modals/DirtyForm'
import Tab from './Tab'

const DataCollectionForm = () => {
  const [formKey, setFormKey] = useState(null)
  const [year, setYear] = useState()

  const [open, setOpen] = useState(false)
  const [openLeave, setOpenLeave] = useState(false)
  const [temporalKey, setTemporalKey] = useState(null)

  const navigate = useNavigate()
  const params = useParams()
  const { setHideNavbar, currentFarm, userRoleSelected, programConfig } = useContext(AppContext)

  const { getAccessTokenSilently } = useAuth0()
  const [dataCollectionStatement, setDataCollectionStatement] = useState(null)

  const [tabs, setTabs] = useState([])
  const [program, setProgram] = useState(null)
  const [formMode, setFormMode] = useState(null)
  const [selectedMetric, setSelectedMetric] = useState(null)

  const changeTab = newValue => {
    if (open) {
      setOpen(false)
      setTemporalKey(null)
      setFormKey(temporalKey)
      lfStore.setItem('dirtyForm', false)
    } else {
      setFormKey(newValue)
      lfStore.setItem('dirtyForm', false)
    }
  }

  const handleChange = async (event, newValue) => {
    const dirtyStatus = await lfStore.getItem('dirtyForm')
    if (dirtyStatus && !open) {
      setTemporalKey(newValue)
      setOpen(true)
    } else {
      changeTab(newValue)
    }
  }

  const handleCancelChange = () => {
    setOpen(false)
    setTemporalKey(null)
  }

  const handleNavBack = async () => {
    const dirtyStatus = await lfStore.getItem('dirtyForm')
    if (dirtyStatus && !openLeave) {
      setOpenLeave(true)
    } else {
      setOpenLeave(false)
      navigate('/management')
    }
  }

  const checkNamespaceDependencies = useCallback(
    async (namespace, dependencies, farm, _year, token) => {
      if (dependencies?.disabled) {
        if (dependencies.disabled.length === 0) {
          return Promise.resolve(true)
        }

        const validationPromises = dependencies.disabled
          .map(disabledDependency => namespacesValidations[disabledDependency])
          .filter(validation => validation !== undefined)
          .map(validation => validation(farm, _year, token))

        return Promise.all(validationPromises).then(
          results => results.find(result => result !== true) || true,
        )
      }

      // All tabs need to check for unresolved findings
      return namespacesValidations.unresolvedFindings(dataCollectionStatement?.id, namespace, token)
    },
    [dataCollectionStatement?.id],
  )

  const handleResetSelectedTab = async () => {
    const selectedTabIndex = tabs.findIndex(item => item.namespace === formKey)
    if (selectedTabIndex !== -1) {
      const newTabs = [...tabs]
      const token = await getAccessTokenSilently()

      const newSelectedTab = newTabs[selectedTabIndex]
      newSelectedTab.status = await checkNamespaceDependencies(
        newSelectedTab.namespace,
        newSelectedTab.dependencies,
        currentFarm,
        year,
        token,
      )

      // When a namespace is changed, all the namespaces that depend on it need to be reloaded
      // This is done by checking the dependencies.reloadNamespace of the selected tab
      if (newSelectedTab?.dependencies?.reloadNamespace?.length) {
        for (const namespaceToReload of newSelectedTab?.dependencies?.reloadNamespace) {
          const tabIndexToReload = tabs.findIndex(item => item.namespace === namespaceToReload)
          const tabToReload = newTabs[tabIndexToReload]
          tabToReload.status = await checkNamespaceDependencies(
            namespaceToReload,
            tabToReload.dependencies,
            currentFarm,
            year,
            token,
          )
        }
      }

      setTabs(newTabs)
    }
  }

  useEffect(() => {
    setHideNavbar(true)
    return () => {
      setHideNavbar(false)
    }
  }, [setHideNavbar])

  useEffect(() => {
    setYear(params.year)
  }, [params])

  useEffect(() => {
    if (!year || !currentFarm || !userRoleSelected) return

    async function loadDataCollectionStatement(year) {
      const token = await getAccessTokenSilently()
      const dataCollectionStatement = await dataCollectionStatementService.getByFarmYear(
        currentFarm.id,
        year,
        userRoleSelected,
        token,
      )
      if (dataCollectionStatement) {
        setDataCollectionStatement(dataCollectionStatement)
      }
    }

    loadDataCollectionStatement(year)
  }, [year, currentFarm, userRoleSelected, getAccessTokenSilently, formKey])

  useEffect(() => {
    if (!dataCollectionStatement) return
    const selectedTab = tabs.find(item => item.namespace === formKey)
    if (!selectedTab) return

    function getFormMode({ tab, actionsAllowed }) {
      if (tab?.status?.disabled) return formModes.Disable

      if (selectedMetric && selectedMetric.isDraft) return formModes.Edit
      if (selectedMetric && selectedMetric.metricStatusId !== metricEventStatus.Open)
        return formModes.View

      if (actionsAllowed.edit) return formModes.Edit
      if (actionsAllowed.review) return formModes.Review
      if (actionsAllowed.view) return formModes.View

      return { Label: 'Sin permisos' }
    }

    setFormMode(
      getFormMode({ tab: selectedTab, actionsAllowed: dataCollectionStatement.actionsAllowed }),
    )
  }, [currentFarm, year, dataCollectionStatement, tabs, formKey, selectedMetric])

  useEffect(() => {
    async function getFormDefinitionsKeys() {
      const token = await getAccessTokenSilently()

      const program = await getProgram(programConfig.programId, token)
      setProgram(program)

      const formDefinitions = await getDataCollectionFormDefinitions(programConfig, token)

      if (formDefinitions?.length > 0) {
        const mapeoLabelsDefinitions = await Promise.all(
          formDefinitions?.map(async formDefinition => {
            const newTab = {
              label: formDefinition?.label,
              dependencies: formDefinition?.dependencies,
              status: await checkNamespaceDependencies(
                formDefinition.namespace,
                formDefinition.dependencies,
                currentFarm,
                year,
                token,
              ),
              namespace: formDefinition?.namespace,
            }
            await lfStore.setItem(formDefinition?.namespace, formDefinition)
            return newTab
          }),
        )
        const firstEnabledTab = mapeoLabelsDefinitions.find(tab => !tab.status.disabled)
        setFormKey(
          prevFormKey => prevFormKey || firstEnabledTab?.namespace || formDefinitions[0]?.namespace,
        )
        setTabs(mapeoLabelsDefinitions)
      }
    }

    if (currentFarm && year && dataCollectionStatement) {
      getFormDefinitionsKeys()
    }
  }, [
    currentFarm,
    getAccessTokenSilently,
    checkNamespaceDependencies,
    year,
    dataCollectionStatement,
    programConfig,
  ])

  return (
    formMode &&
    formKey &&
    year &&
    currentFarm &&
    dataCollectionStatement && (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100%' }}>
        <Box
          alignItems="center"
          display="flex"
          flexGrow={0}
          justifyContent="space-around"
          m={0}
          position="sticky"
        >
          <Box display="flex">
            <IconButton onClick={() => handleNavBack()}>
              <ArrowBackIosIcon />
            </IconButton>
          </Box>
          <Box display="flex" flexGrow={1} justifyContent="center">
            <Typography variant="bodyBold">CARGA DE DATOS ANUALES</Typography>
            <Typography
              sx={{ ml: 1, textTransform: 'lowercase' }}
            >{`(${formMode.label})`}</Typography>
          </Box>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            padding={0.5}
            spacing={1}
          >
            <Stack
              alignItems="right"
              direction="column"
              display="flex"
              justifyContent="center"
              spacing={0}
              textAlign="right"
            >
              <Typography sx={{ lineHeight: '0.9rem' }} variant="bodyBold">
                {currentFarm?.name.toUpperCase()}
              </Typography>
              <Typography sx={{ lineHeight: '0.8rem' }} variant="captionLight">
                {`${program?.id !== 99 ? 'Programa: ' : ''}${program?.name}`}
              </Typography>
              <Typography sx={{ lineHeight: '0.8rem' }} variant="captionLight">
                {`Rol: ${roleTraduction(userRoleSelected)}`}
              </Typography>
            </Stack>
            <Typography
              fontSize="1.8rem"
              fontWeight="600"
              pr={1}
              sx={{ lineHeight: '1.8rem' }}
              variant="h6"
            >
              {year || ''}
            </Typography>
          </Stack>
        </Box>

        <Divider />
        <Stack direction="row" sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs aria-label="management-activities" gap={2} value={formKey} onChange={handleChange}>
            {tabs.map(tab => (
              <Tab
                key={tab.namespace}
                disabled={tab?.status?.disabled}
                label={tab.label}
                tooltipError={tab?.status?.error}
                totalError={tab?.status?.totalError}
                typeError={tab?.status?.disabled ? 'error' : 'warning'}
                value={tab.namespace}
              />
            ))}
          </Tabs>
        </Stack>

        <FormContainer
          dataCollectionStatementId={dataCollectionStatement.id}
          formKey={formKey}
          formMode={formMode}
          resetTab={handleResetSelectedTab}
          selectedMetricEvent={selectedMetric}
          setSelectedMetricEvent={setSelectedMetric}
          year={year}
        />

        {/* Dialog para caso que sigue en el mismo año */}
        <DirtyForm handleClose={handleCancelChange} handleConfirm={handleChange} open={open} />

        {/* Dialog para caso que vuelve a la tabla */}
        <DirtyForm
          handleClose={() => {
            setOpenLeave(false)
          }}
          handleConfirm={() => {
            setOpenLeave(false)
            navigate('/management')
          }}
          open={openLeave}
        />
      </Box>
    )
  )
}

export default DataCollectionForm
